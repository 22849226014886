import React from "react";
import { Form, Button } from "react-bootstrap";

const RegisterPage = (props) => {
    return (
        <>
            <Form className="py-4" noValidate validated={props.validated} onSubmit={props.sumbitRegisterHandler}>
                <Form.Group>
                    <Form.Label>Name <span className="required">*</span></Form.Label>
                    <Form.Control type="text" value={props.name} onChange={(event) => props.setName(event.target.value)} required />
                    <Form.Control.Feedback type="invalid">
                        Name is required
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Email <span className="required">*</span></Form.Label>
                    <Form.Control type="email" value={props.email} onChange={(event) => props.setEmail(event.target.value)} required />
                    <Form.Control.Feedback type="invalid">
                        Email is required
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Address <span className="required">*</span></Form.Label>
                    <Form.Control type="textarea" value={props.address} onChange={(event) => props.setAddress(event.target.value)} required />
                    <Form.Control.Feedback type="invalid">
                        Address is required
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Postal Office <span className="required">*</span></Form.Label>
                    <Form.Control type="text" value={props.postalOffice} onChange={(event) => props.setPostalOffice(event.target.value)} required />
                    <Form.Control.Feedback type="invalid">
                        Postal Office is required
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Postal Code <span className="required">*</span></Form.Label>
                    <Form.Control type="number" value={props.postalCode} onChange={(event) => props.setPostalCode(event.target.value)} required />
                    <Form.Control.Feedback type="invalid">
                        Postal Code is required
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Phone Number <span className="required">*</span></Form.Label>
                    <Form.Control type="number" value={props.phoneNumber} onChange={(event) => props.setPhoneNumber(event.target.value)} required />
                    <Form.Control.Feedback type="invalid">
                        Phone Number is required
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Login Id <span className="required">*</span></Form.Label>
                    <Form.Control type="text" value={props.loginId} onChange={(event) => props.setLoginId(event.target.value)} required />
                    <Form.Control.Feedback type="invalid">
                        Login Id is required
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Password <span className="required">*</span></Form.Label>
                    <Form.Control type="text" value={props.password} onChange={(event) => props.setPassword(event.target.value)} required />
                    <Form.Control.Feedback type="invalid">
                        Password is required
                    </Form.Control.Feedback>
                </Form.Group>
                <br />
                <Button type="submit" className="btn-custom close-btn" >Register</Button>
            </Form>
           
        </>
    )
}

export default RegisterPage;