import React, { useState, useEffect } from "react";
import { Button, Modal, Form, Row, Col, Card, Tabs, Tab, Table } from "react-bootstrap";
import api from "../../config/api";
import { getAdminConfig } from "../../config/requestConfig";
import EmptyData from "../common/EmptyData";
import Loading from "../common/Loading";


const DeliveryBoyRequestItem = ({ request }) => {

    var today = new Date(request.requestedAt),
        // get month in two digit
        month =
            today.getMonth() + 1 < "10"
                ? "0" + (today.getMonth() + 1)
                : today.getMonth() + 1,
        // get month in two digit
        day = today.getDate() < "10" ? "0" + today.getDate() : today.getDate(),
        // get current date
        requestedAt = day + "-" + month + "-" + today.getFullYear();
    return (
        <>
            <tr>
                <td>{requestedAt}</td>
                <td>{request.shop.name}<br />{request.shop.address}<br />{request.shop.postalOffice}<br />{request.shop.postalCode}<br />{request.shop.phoneNumber}</td>
                <td>{request.name}<br />{request.address}<br />{request.postalOffice}<br />{request.postalCode}<br />{request.phoneNumber} </td>
                <td>Item Count : {request.itemCount}<br />
                    Items : <br />{request.items.map((item, index) => {
                        return item + "; ";
                    })} <br /> Approximate weight : {request.weight} KG
                </td>
                <td>Bill Amount : {request.billAmount}<br /> Payment Type : <br /> {request.paymentType} <br /> Payment status : {request.paymentStatus}  </td>

                <td>Distance : {request.distance} KM <br /> Charge : {request.deliveryCharge} € </td>

            </tr>
        </>
    )
}
const ShopRequestItem = ({ request, status }) => {

    var today = new Date(request.requestedAt),
        // get month in two digit
        month =
            today.getMonth() + 1 < "10"
                ? "0" + (today.getMonth() + 1)
                : today.getMonth() + 1,
        // get month in two digit
        day = today.getDate() < "10" ? "0" + today.getDate() : today.getDate(),
        // get current date
        requestedAt = day + "-" + month + "-" + today.getFullYear();
    return (
        <>
            <tr>
                <td>{requestedAt}</td>
                <td>{request.name}<br />{request.address}<br />{request.postalOffice}<br />{request.postalCode}<br />{request.phoneNumber} </td>
                {status !== "Requested" && <td>{request.assignedTo.name}</td>}
                <td>Item Count : {request.itemCount}<br />
                    Items : <br />{request.items.map((item, index) => {
                        return item + "; ";
                    })} <br /> Approximate weight : {request.weight} KG
                </td>
                <td>Bill Amount : {request.billAmount}<br /> Payment Type : <br /> {request.paymentType} <br /> Payment status : {request.paymentStatus}  </td>

                <td>Distance : {request.distance} KM <br /> Charge : {request.deliveryCharge} € </td>

            </tr>
        </>
    )
}

const RequestTable = ({ status, requests, requestType,flag }) => {
    return (
        requests.filter((request) => request.deliveryStatus === status).length === 0&&!flag ?
            <EmptyData/>
            :
            requests.filter((request) => request.deliveryStatus === status).length === 0&&flag ?
            <Loading />
            :
            <Table responsive className="table no-wrap">
                <thead className="table-primary">
                    <tr>
                        <th>Date</th>
                        {requestType !== "shop"?
                        <th>Shop Details</th>:""}
                        <th>Customer Details</th>
                        {status !== "Requested" && requestType === "shop" ?
                        <th>Assigned</th>:""}
                        <th>Item Details</th>
                        <th>Billing Details</th>
                        <th>Distance/Charge</th>

                    </tr>
                </thead>
                <tbody>
                    {
                        requests.length !== 0 && requests.filter((request) => request.deliveryStatus === status).map((request, index) => (
                            requestType === "shop" ?
                                <ShopRequestItem key={index} request={request} status={status} />
                                :
                                <DeliveryBoyRequestItem key={index} request={request} />
                        ))
                    }
                </tbody>
            </Table>
    )
}

export const RequestDetails = ({
    requests = [],
    setRequests,
    setFilterItem,
    filterItem,
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    requestType,
    date

}) => {
    const [options, setOptions] = useState([]);
    const [tabKey, setTabKey] = useState("Requested");
    const [flag, setFlag] = useState(true);
    useEffect(() => {
        fetchOptionLists();
        setRequests([])
    }, [requestType]);
    const resetFilter = () => {
        setStartDate(date);
        setEndDate(date);
        setRequests([])
        setFilterItem("")
    }
    const fetchOptionLists = () => {
        api.get(`/admin/${requestType}/`, getAdminConfig()).then((response) => {
            setOptions(response.data);
            setFlag(false);
        })
    }
    return (

        <>
            <Row className="gap-2 align-items-center">
            <div className="hstack gap-2 mt-0">
                <Col sm={12} md={3} lg={3} style={{ padding: "5px" }}>
                    <Form.Group>
                        <Form.Label>Select {requestType==="shop"?"Shop":"Delivery Boy"}</Form.Label>
                        <Form.Control
                            as="select"
                            value={filterItem} onChange={(event) => setFilterItem(event.target.value)}
                        >
                            <option value="">Select</option>
                            {options.map((option, index) => {
                                return <option key={index} value={option.id}>{option.name}</option>
                            })
                            }
                        </Form.Control>
                    </Form.Group>
                </Col>
                <Col sm={12} md={3} lg={3} style={{ padding: "5px" }}>
                    <Form.Group>
                        <Form.Label>From Date</Form.Label>
                        <Form.Control
                            type="date"
                            value={startDate}
                            onChange={(event) => setStartDate(event.target.value)}
                        />
                    </Form.Group>
                    <Form.Group></Form.Group>

                </Col>
                <Col sm={12} md={3} lg={3} style={{ padding: "5px" }}>
                    <Form.Group>
                        <Form.Label>End Date</Form.Label>
                        <Form.Control
                            type="date"
                            value={endDate}
                            onChange={(event) => setEndDate(event.target.value)}
                        />
                    </Form.Group>
                    <Form.Group></Form.Group>

                </Col>
                <Col style={{ padding: "12px",marginTop:"10px" }}>
                    <Button className="btn-custom reset-btn"
                        onClick={resetFilter}
                    >
                        Reset
                    </Button>
                </Col>
                </div>
            </Row>
            <div className="container pt-3">
            <Tabs defaultActiveKey="Requested" id="uncontrolled-tab-example" activeKey={tabKey} onSelect={(k) => setTabKey(k)}>
                <Tab eventKey="Requested" title={"Requested (" + requests.filter((request) => request.deliveryStatus === "Requested").length + ")"}>
                    <RequestTable status={tabKey} requests={requests} requestType={requestType} flag={flag}/>
                </Tab>
                <Tab eventKey="Assigned" title={"Assigned (" + requests.filter((request) => request.deliveryStatus === "Assigned").length + ")"}>
                    <RequestTable status={tabKey} requests={requests} requestType={requestType} flag={flag}/>
                </Tab>
                <Tab eventKey="Pickedup" title={"Pickedup (" + requests.filter((request) => request.deliveryStatus === "Pickedup").length + ")"}>
                    <RequestTable status={tabKey} requests={requests} requestType={requestType} flag={flag}/>
                </Tab>
                <Tab eventKey="Delivered" title={"Delivered (" + requests.filter((request) => request.deliveryStatus === "Delivered").length + ")"}>
                    <RequestTable status={tabKey} requests={requests} requestType={requestType} flag={flag}/>
                </Tab>

            </Tabs>
            </div>
        </>
    )
}

