import React, { useState } from "react";
import { Link, Route, Switch,useLocation } from "react-router-dom";
import { Nav,Row,Col,Dropdown } from "react-bootstrap";
import Header from "./Header";
import Home from "./Home";
import DeliveryBoy from "./DeliveryBoy";
import Request from "./Request";
import ShopBill from "./ShopBill";
import AddShopBill  from "./AddShopBill";
import DeliveryPayment from "./DeliveryPayment";
import AddDeliveryPayment from "./AddDeliveryPayment";
import Shop from "./Shop";
import {HomeIcon,RequestIcon,CreditCard,PlusSqure,Users,ShoppingBag} from "../svgs"
const Dashboard = ({ admin, setAdmin }) => {
    const {pathname} = useLocation();
  
    return (
        <>
        <Header admin={admin} setAdmin={setAdmin} />
        <div className="admin-layout-container">
           <div className="d-flex">
            <div lg={2} className="sidebar" id="side-bar">
                <Nav defaultActiveKey="/admin" className="side-menu" id="side-menu">
                    <Link to="/admin" className={`text ${pathname === "/admin"?'active':''}`}>
                        <HomeIcon stroke='#404040' strokeWidth={1}  />
                        <span>Home</span>
                    </Link>
                    <Link to="/admin/requests" className={`text ${pathname === "/admin/requests"?'active':''}`}>
                        <RequestIcon stroke='#404040' strokeWidth={1}/>
                        <span>Request</span>
                    </Link>
                    <Link to="/admin/addShopBill" className={`text ${pathname === "/admin/addShopBill"?'active':''}`}>
                        <PlusSqure stroke='#404040' strokeWidth={1}/>
                        <span>Add Shop Bill</span>
                    </Link>
                    <Link to="/admin/shopBills" className={`text ${pathname === "/admin/shopBills"?'active':''}`}>
                        <CreditCard stroke='#404040' strokeWidth={1}/>
                        <span>Shop Bill</span>
                    </Link>
                    <Link to="/admin/addPayment" className={`text ${pathname === "/admin/addPayment"?'active':''}`}>
                        <PlusSqure stroke='#404040' strokeWidth={1}/>
                        <span>Add Payment</span>
                    </Link>
                    <Link to="/admin/deliveryPayments" className={`text ${pathname === "/admin/deliveryPayments"?'active':''}`}>
                        <CreditCard stroke='#404040' strokeWidth={1}/>
                        <span>Delivery Payments</span>
                    </Link>
                    <Link to="/admin/deliveryBoys" className={`text ${pathname === "/admin/deliveryBoys"?'active':''}`}>
                        <Users stroke='#404040' strokeWidth={1}/>
                        <span>Delivery Boys</span>
                    </Link>
                    <Link to="/admin/shops" className={`text ${pathname === "/admin/shops"?'active':''}`}>
                        <ShoppingBag stroke='#404040' strokeWidth={1}/>
                        <span>Shops</span>
                    </Link>

                </Nav>
        </div>
        <section id="section">
                <Switch>
                    <Route exact path="/admin/" component={Home} />
                    <Route exact path="/admin/requests" component={Request}/>
                    <Route exact path="/admin/shopBills" component={ShopBill}/>
                    <Route exact path="/admin/addShopBill" component={AddShopBill}/>
                    <Route exact path="/admin/addPayment" component={AddDeliveryPayment}/>
                    <Route exact path="/admin/deliveryPayments" component={DeliveryPayment}/>
                    <Route exact path="/admin/deliveryBoys" component={DeliveryBoy}/>
                    <Route exact path="/admin/shops" component={Shop}/>
                </Switch>
        </section>
        </div>
        </div>
    </>

    );
}
export default Dashboard;