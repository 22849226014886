import React from "react";
import {Link, BrowserRouter as Router,Route,Switch} from "react-router-dom";

const Home = () =>{
    return(
            <div className="container common-home" style={{textAlign:"center"}}>
                 <img
                        src={process.env.PUBLIC_URL + "/images/logo.png"}
                        className="emblem"
                        />
                        <div className="p-2">
                        <Link to="/shop" className="btn-custom"> Shop</Link> 
                        <Link to="/delivery-boy" className="btn-custom" style={{marginLeft:"15px"}}>Delivery Boy</Link>
                        </div>
            </div>
    )
}
export default Home;