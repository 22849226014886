import React, { useState, useEffect } from "react";
import { Modal, Form, Button } from "react-bootstrap";
import {toast} from "react-toastify"
const ForgetPassword = ({ showForgetPassword, closeForgetPassword, forgetPassword }) => {
    const [userId, setUserId] = useState("")
    const [alert, setAlert] = useState({});
    const [validated, setValidated] = useState(false);
    const onSubmitHandler = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation();
            event.preventDefault();
            setValidated(true);
        }
        else {
            event.preventDefault();
            forgetPassword(userId, setAlert);
        }

    }
    return (
        <Modal
            show={showForgetPassword}
            onHide={()=>{closeForgetPassword();setAlert({});setUserId("")}}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header className="pb-3 px-3 pt-4">
                <Modal.Title>Forget Password</Modal.Title>
                <div class="close-container" onClick={()=>{closeForgetPassword();setAlert({});setUserId("")}}>
                <div class="close-line1"></div>
                <div class="close-line2"></div>
                </div>
            </Modal.Header>
                <Form noValidate validated={validated} onSubmit={onSubmitHandler}>
                    
                <Modal.Body>
                    <Form.Label>User Id <span className="required">*</span></Form.Label>
                    <Form.Control type="text" value={userId} onChange={(event) => setUserId(event.target.value)} required />
                    <Form.Control.Feedback type="invalid">
                        Please provide a valid user id.
                    </Form.Control.Feedback>
                    {
                        Object.keys(alert).length !== 0 && 
                        <small style={{ color: "green" }}>{alert.message}<br />{alert.email}</small>
                    }
                    </Modal.Body>
                    <div className="p-4">
                        <Button className="btn-custom reset-btn" onClick={()=>{closeForgetPassword();setAlert({});setUserId("")}}>
                            Close
                        </Button>
                        <Button type="submit" className="btn-custom close-btn">
                            Submit
                        </Button>
                    </div>
                    
                </Form>

        </Modal>
    );
};
export default ForgetPassword;
