import React from "react";
const Home = () =>{
    return(
        <div className="container pt-3">
            <div className="d-flex align-items-center justify-content-between mb-3">
                <div className="admin-header">Admin Home</div> 
            </div>
        </div>
    )
}
export default Home;