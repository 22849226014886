import React, { useState, useEffect } from "react";
import { Button, Modal, Form, Row, Col, Card, Tabs, Tab, Table, Accordion } from "react-bootstrap";
import api from "../../config/api";
import { getUserConfig } from "../../config/requestConfig";
import ConfirmModal from "../common/ConfirmModal";
import { successModule } from "../../utils/utils";
import EmptyData from "../common/EmptyData";
import Loading from "../common/Loading";
import { ChevronRight } from "../svgs/ChevronRight";
import { error, success } from "toastr";

const RequestItem = ({ request, fetchRequest,currentLocation,startDate,endDate }) => {
    const [showModal, setShowModal] = useState(false);
    const [chevronTransform, setChevronTransform] = useState(180)
    const [collaps, setCollaps] = useState(null);

    const editRequest = () => {
        let requestStatus = request.deliveryStatus ==="Requested"? "Assigned":
                            request.deliveryStatus ==="Assigned"? "Pickedup": "Delivered";
        setShowModal(false);
        api.put(`/deliveryBoy/deliveryRequests/${requestStatus}/${request.id}`, {}, getUserConfig()).then((response) => {
            fetchRequest(startDate,endDate);
            successModule("Requested task has been " + requestStatus)
        })
    }
    const closeModalHandler = () => {
        setShowModal(false)
    }
    var today = new Date(request.requestedAt),
        // get month in two digit
        month =
            today.getMonth() + 1 < "10"
                ? "0" + (today.getMonth() + 1)
                : today.getMonth() + 1,
        // get month in two digit
        day = today.getDate() < "10" ? "0" + today.getDate() : today.getDate(),
        // get current date
        requestedAt = day + "-" + month + "-" + today.getFullYear();

    const showContentHandler = (id)=>{
       
        const content = document.getElementById(`accordion-content-${id}`)
        if(content.className==='accordion-content')
        {
            content.className = 'accordion-content active'
            setChevronTransform(360)
        }
        else 
        {
            content.className = 'accordion-content'
            setChevronTransform(180)
        }
        
    }
    return (
       <>
            <Col sm={12} lg={6} xl={6} className="mb-3">
            <Accordion >
                <div className="accordion-item">
                <div className="accordion-header" onClick={()=>showContentHandler(request.id)}>
                                <div className="card-name">#0901902900<br/>
                                {request.shop.name},{request.shop.address}<br/>
                                {request.name},{request.address}<br/>
                                {request.deliveryStatus ==="Requested"?
                                <span class="badge badge-primary">New</span>:
                                request.deliveryStatus ==="Assigned"? 
                                <span class="badge badge-success">Accepted</span>: 
                                <span class="badge badge-secondary">Delivered</span>}
                                </div>
                                <div className="accordion-chevron-wrapper">
                                    <div className="accordion-chevron">
                                        <ChevronRight stroke='#404040' strokeWidth={1} rotate={chevronTransform}/>
                                    </div>
                                </div>
                </div>
                <div className={`accordion-content`} id={`accordion-content-${request.id}`}>
                            <div className="d-flex align-items-center justify-content-center">
                                <div className="request-card-container">
                                    <div className="card-items">
                                        {request.deliveryStatus==="Pickedup"?
                                        <iframe width="100%" height="200px" style={{borderRadius:"15px"}} class="maps" frameborder="0" id="mapnavi" name="mapnavi" 
                                        src={`https://www.google.com/maps/embed/v1/directions?origin=${currentLocation.latitude},${currentLocation.longitude}&destination=${request.address},${request.postalOffice},${request.postalCode}&key=AIzaSyC-5CY9mOCeg5Y3IhPqi_Yd0-DZtWrJl-E`}>
                                        </iframe>
                                        :
                                         <iframe width="100%" height="200px" style={{borderRadius:"15px"}} class="maps" frameborder="0" id="mapnavi" name="mapnavi" 
                                         src={`https://www.google.com/maps/embed/v1/directions?origin=${currentLocation.latitude},${currentLocation.longitude}&destination=${request.shop.address},${request.shop.postalOffice},${request.shop.postalCode}&key=AIzaSyC-5CY9mOCeg5Y3IhPqi_Yd0-DZtWrJl-E`}>
                                         </iframe>
                                        }
                                        <div className="card-name">{request.shop.name}</div>
                                        <div className="card-address">{request.shop.address},{request.shop.postalOffice},{request.shop.postalCode}</div>
                                        <div className="card-name">{request.name}</div>
                                        <div className="card-address">{request.address},{request.postalOffice},{request.postalCode}</div>
                                        <Button type="button" className="card-edit-button" onClick={()=>setShowModal(true)} disabled={request.deliveryStatus ==="Delivered"?true:false}>
                                        {request.deliveryStatus ==="Requested"? "Accept Task":
                                            request.deliveryStatus ==="Assigned"? "Pickup Task": "Delivered"}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
            </Accordion>

            </Col>

            <ConfirmModal showModal={showModal} closeModal={closeModalHandler} confirmModal={editRequest} />
        </>
    )
}

const Home = ({user}) => {
    const [requests, setRequests] = useState([]);
    const [flag, setFlag] = useState(true);
    const [currentLocation, setCurrenctLocation] = useState({latitude:"",longitude:""})
    var today = new Date(),
        // get month in two digit
        month =
            today.getMonth() + 1 < "10"
                ? "0" + (today.getMonth() + 1)
                : today.getMonth() + 1,
        // get month in two digit
        day = today.getDate() < "10" ? "0" + today.getDate() : today.getDate(),
        // get current date
        date = today.getFullYear() + "-" + month + "-" + day;

    const [deliveryBoyStartDate, setDeliveryBoyStartDate] = useState(date);
    const [deliveryBoyEndDate, setDeliveryBoyEndDate] = useState(date);

    useEffect(()=>{
        const options = {
                enableHighAccuracy: false,
                timeout: 5000,
                maximumAge: 0,
            };
          function success(pos) {
            const crd = pos.coords;
            const latitude = crd.latitude;
            const longitude = crd.longitude;
            setCurrenctLocation({...currentLocation,latitude,longitude})
          }
          const error = ()=>{
            successModule("Allow your current location");
          }
          navigator.geolocation.getCurrentPosition(success, error,options);
    },[]);

    useEffect(() => {
        fetchRequest(deliveryBoyStartDate,deliveryBoyEndDate);
    }, [deliveryBoyStartDate, deliveryBoyEndDate])

    const fetchRequest = (startDate,endDate) => {

        api.get(`/deliveryBoy/deliveryRequests/${startDate}/${endDate}`, getUserConfig()).then((response) => {
            setRequests(response.data);
            setFlag(false)
        })
    }

    const resetFilter = () => {
        setDeliveryBoyStartDate(date);
        setDeliveryBoyEndDate(date);
    }
    return (

        <div className="user-container-layout">
            
            <div className="container pt-3">
                
            <br />
            <Row className="gap-2 align-items-center">
                    <Col sm={12} md={6} lg={3} style={{ padding: "5px" }}>
                        <Form.Group>
                            <Form.Label>Select Date</Form.Label>
                            <Form.Control
                                type="date"
                                value={deliveryBoyStartDate}
                                onChange={(event) => setDeliveryBoyStartDate(event.target.value)}
                            />
                        </Form.Group>
                        <Form.Group></Form.Group>

                    </Col>
                    {/* <Col sm={12} md={6} lg={3} style={{ padding: "5px" }}>
                        <Form.Group>
                            <Form.Label>End Date</Form.Label>
                            <Form.Control
                                type="date"
                                value={deliveryBoyEndDate}
                                onChange={(event) => setDeliveryBoyEndDate(event.target.value)}
                            />
                        </Form.Group>
                        <Form.Group></Form.Group>

                    </Col>
                    <Col style={{marginTop: "10px"}}>
                        <Button className="btn-custom reset-btn" onClick={resetFilter}>
                            Reset
                        </Button>
                    </Col> */}
            </Row>
                {requests.length === 0&&!flag ?
                    <EmptyData />
                    :
                    requests.length === 0&&flag ?
                    <Loading />
                    :
                        <div className="request-details">
                             
                            <Row>
                            <div className="d-flex align-items-center justify-content-between mb-3">
                                <div className="admin-header">Take Tasks</div>
                            </div>
                            {
                                requests.length !== 0 && requests.map((request, index) => (
                                    <RequestItem key={index} request={request} fetchRequest={fetchRequest} startDate={deliveryBoyStartDate} endDate={deliveryBoyEndDate} currentLocation={currentLocation} />
                                ))
                            }
                            </Row>
                        </div>
                }
            </div>
        </div>
    )
}
export default Home;