import React, { useState, useEffect } from "react";
import { Table, } from "react-bootstrap";
import api from "../../config/api";
import { getAdminConfig } from "../../config/requestConfig";
import { successModule } from "../../utils/utils";
import EmptyData from "../common/EmptyData";
import Loading from "../common/Loading";


const ShopItem = ({ shop, fetchShops }) => {


    const editShop = (event) => {
        const checked = event.target.checked;
        const status = checked===true?"active":"inactive";
        const shopId = shop.loginId;
        if(shopId&&status)
        api.put(`/admin/shop/${shopId}/${status}`, {}, getAdminConfig()).then((response) => {
            fetchShops();
            successModule("Shop is " + status);
        })

    }

    return (
        <>
            <tr>
                <td>{shop.name}</td>
                <td>{shop.loginId} </td>
                <td>{shop.email} </td>

                <td>{shop.address}<br />
                    {shop.postalOffice} <br />
                    {shop.postalCode}
                </td>
                <td>{shop.phoneNumber} </td>

                <td>
                    <div>
                        <div className="switch-container">
                            <input className="switch-input" type="checkbox" role="switch" onChange={editShop} checked={shop.status === "active" ? true : false} />
                            <label className="switch-label">{shop.status === "active" ? "Active" : "Inactive"}</label>
                        </div>
                    </div>
                </td>
            </tr>

        </>
    )
}

const Shop = () => {

    const [shops, setShops] = useState([]);
    const [flag, setFlag] = useState(true);
    useEffect(() => {
        fetchShops();
    }, [])

    const fetchShops = () => {
        api.get(`/admin/shop/`, getAdminConfig()).then((response) => {
            setShops(response.data);
            setFlag(false);
        })
    }
    return (

        <div>
            <div className="container pt-3">
                <div className="d-flex align-items-center justify-content-between mb-3">
                    <div className="admin-header">Shops</div>
                </div>
                <br />
                {
                    shops.length === 0 && !flag ?
                        <EmptyData />
                        :
                        shops.length === 0 && flag ?
                            <Loading />
                            :
                            <Table responsive className="table now-wrap" style={{ overflowX: "scroll" }}>
                                <thead className="table-primary">
                                    <tr>
                                        <th>Shop Name</th>
                                        <th>LoginId</th>
                                        <th>email</th>
                                        <th>address</th>
                                        <th>Phone Number</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        shops.length !== 0 && shops.map((shop, index) => (
                                            <ShopItem key={index} shop={shop} shops={shops} fetchShops={fetchShops} />
                                        ))
                                    }
                                </tbody>
                            </Table>
                }
            </div>
        </div>
    )
}
export default Shop;