import React, { useState, useEffect } from "react";
import { Row, Col, Card, Form, Button, Alert, Tabs, Tab, Toast } from "react-bootstrap";
import api from '../../config/api';
import ForgetPassword from "../common/ForgetPassword";
import LoginPage from "../common/LoginPage";
import { errorMessage, successMessage } from "../../utils/utils";
import RegisterPage from "../common/RegisterPage";
import { LoginIcon } from "../svgs";
const Login = ({ setShop }) => {

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [postalOffice, setPostalOffice] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [loginId, setLoginId] = useState("");
  const [password, setPassword] = useState("");
  const [loginValidated, setLoginValidated] = useState(false);
  const [registerValidated, setRegisterValidated] = useState(false);
  const [showForgetPassword, setShowForgetPassword] = useState(false);

  //register submit 

  const resetForm = () => {
    setName("");
    setEmail("");
    setAddress("");
    setPostalOffice("");
    setPostalCode("");
    setPhoneNumber("");
    setLoginId("");
    setPassword("");
  }

  const onSumbitRegisterHandler = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      event.preventDefault();
      setRegisterValidated(true);
    }
    else{
      event.preventDefault();
      let content = { name, email, address, postalOffice, postalCode, phoneNumber, loginId, password }
      api.post(`/shop/register`, content).then((response) => {
        resetForm();
        successMessage("Email as been sent for account activation");
  
      })
    }
    
  }
  //login submit
  const onSumbitLoginHandler = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      event.preventDefault();
      setLoginValidated(true);
    }
    else {
      event.preventDefault();
      let content = { loginId, password }
      api.post("/shop/login", content).then((res) => {
        let loggedShop = JSON.stringify(res.data);
        localStorage.setItem("loggedShop", loggedShop);
        setShop(loggedShop);
        resetForm();
      }).catch((err) => {
        errorMessage("Invalid userId or password");
        console.log(err);
      })
    }
  }



  //forget password
  const closeForgetPassword = () => {
    setShowForgetPassword(false);

  }
  const openForgetPassword = () => {
    setShowForgetPassword(true);
  }

  const forgetPassword = (userId, setAlert) => {
    api.put(`/shop/forgetPassword/${userId}`, {}).then((response) => {
      setShowForgetPassword(true);
      let content = { "message": "Generated your new Password", "email": "Kindly Open your email account " }
      setAlert(content);
    }).catch((ex) => {
      errorMessage("Invalid User ID / User is Inactive");
    })
  }
  return (
    <>
      <Row>
        <Col lg={8} className="left-section" style={{ position: "sticky", top: 0 }}>
          <img
            src={process.env.PUBLIC_URL + "/images/logo.png"}
            className="emblem"
          />
        </Col>
        <Col lg={4} className="right-section">
          <h4 style={{ align: "center" }}>
          <div className="header-name"><LoginIcon stroke='#404040' strokeWidth={2}/> SHOP LOGIN</div>
          </h4>
          
          <Tabs defaultActiveKey="login" id="uncontrolled-tab-example">
            <Tab eventKey="login" title="Login">
              <LoginPage
                userId={loginId}
                password={password}
                setUserId={setLoginId}
                setPassword={setPassword}
                submitHandler={onSumbitLoginHandler}
                forgetPassword={openForgetPassword}
                validated={loginValidated}
              />
            </Tab>
            <Tab eventKey="register" title="Register">
              <RegisterPage
              name={name}
              email={email}
              address={address}
              postalOffice={postalOffice}
              postalCode={postalCode}
              phoneNumber={phoneNumber}
              loginId={loginId}
              password={password}
              setName={setName}
              setEmail={setEmail}
              setAddress={setAddress}
              setPostalCode={setPostalCode}
              setPostalOffice={setPostalOffice}
              setPhoneNumber={setPhoneNumber}
              setLoginId={setLoginId}
              setPassword={setPassword}
              sumbitRegisterHandler={onSumbitRegisterHandler}
              validated={registerValidated}
            />
              <br />
              <br />
              <br/>
            </Tab>
          </Tabs>
       
        </Col>
      
      </Row>
      <ForgetPassword
        showForgetPassword={showForgetPassword}
        closeForgetPassword={closeForgetPassword}
        forgetPassword={forgetPassword}
      />
    </>
  );
};
export default Login;
