import React, { useState, useEffect } from "react";
import { Button, Modal, Form, Row, Col, Card, Tabs, Tab, Table, Alert } from "react-bootstrap";
import api from "../../config/api";
import { getAdminConfig } from "../../config/requestConfig";
import { successModule } from "../../utils/utils";
import ConfirmModal from "../common/ConfirmModal";
import EmptyData from "../common/EmptyData";
import Loading from "../common/Loading";

const RequestItem = ({ request, fetchRequest }) => {


    var today = new Date(request.requestedAt),
        // get month in two digit
        month =
            today.getMonth() + 1 < "10"
                ? "0" + (today.getMonth() + 1)
                : today.getMonth() + 1,
        // get month in two digit
        day = today.getDate() < "10" ? "0" + today.getDate() : today.getDate(),
        // get current date
        requestedAt = day + "-" + month + "-" + today.getFullYear();
    return (
        <>
            <tr>
                <td>{requestedAt}</td>
                <td>{request.name}<br />{request.address}<br />{request.postalOffice}<br />{request.postalCode}<br />{request.phoneNumber} </td>
                <td>{request.assignedTo.name}</td>
                <td>Item Count : {request.itemCount}<br />
                    Items : <br />{request.items.map((item, index) => {
                        return item + "; ";
                    })} <br /> Approximate weight : {request.weight} KG
                </td>
                <td>Bill Amount : {request.billAmount}<br /> Payment Type : <br /> {request.paymentType} <br /> Payment status : {request.paymentStatus}  </td>

                <td>Distance : {request.distance} KM <br /> Charge : {request.deliveryCharge} € </td>

            </tr>
        </>
    )
}

const AddShopBill = () => {

    const [shopRequests, setShopRequests] = useState([]);
    const [shops, setShops] = useState([]);
    const [flag, setFlag] = useState(true);
    var today = new Date(),
        month =
            today.getMonth() + 1 < "10"
                ? "0" + (today.getMonth() + 1)
                : today.getMonth() + 1,
        day = today.getDate() < "10" ? "0" + today.getDate() : today.getDate(),
        date = today.getFullYear() + "-" + month + "-" + day;

    const [filterShopId, setFilterShopId] = useState("");
    const [shopStartDate, setShopStartDate] = useState(date);
    const [shopEndDate, setShopEndDate] = useState(date);
    const [showModal, setShowModal] = useState(false);
    const [totalAmount, setTotalAmount] = useState(0)

    useEffect(() => {
        fetchShopRequest(filterShopId, shopStartDate, shopEndDate);
    }, [filterShopId, shopStartDate, shopEndDate]);

    const fetchShopRequest = (filterShopId, shopStartDate, shopEndDate) => {
        if (filterShopId !== "" && shopStartDate !== "" && shopEndDate !== "") {
            api.get(`/admin/shopRequest/${filterShopId}/${shopStartDate}/${shopEndDate}`, getAdminConfig()).then((response) => {
                // setShopRequests(response.data);
                setShopRequests(response.data.filter((fitlerData) => fitlerData.deliveryStatus === "Delivered" && fitlerData.deliveryPaymentStatus === "Waiting"))
            })
        }
    }

    useEffect(() => {
        if(shopRequests.length!==0)
        for (const [i, shopRequest] of shopRequests.entries()) {
            setTotalAmount((prev) => prev + shopRequest.deliveryCharge)
        }
    }, [shopRequests])

    useEffect(() => {
        fetchShops();
    }, []);

    const resetFilter = () => {
        setFilterShopId("");
        setShopStartDate(date);
        setShopEndDate(date);
        setShopRequests([])
    }
    const fetchShops = () => {
        api.get(`/admin/shop/`, getAdminConfig()).then((response) => {
            setShops(response.data);
            setFlag(false);
        })
    }

    const closeModalHandler = () => {
        setShowModal(false)
    }

    const AddShopBill = () => {
        if (shopRequests.length !== 0 && filterShopId) {
            let content = { "shop": filterShopId, requests: shopRequests.map((shopRequest) => shopRequest.id), "totalAmount": totalAmount }
            api.post(`/admin/shopBill/`, content, getAdminConfig()).then((response) => {
                setShopRequests([]);
                resetFilter();
                setShowModal(false)
                successModule("Successfully requested")

            })
        }
    }

    return (

        <>
            <div className="container pt-3">
                <div className="d-flex align-items-center justify-content-between mb-3">
                    <div className="admin-header">Shop Bill</div> 
                </div>
        
            <br/>
            <Row>
            <div className="d-flex hstack gap-2 mt-0">
                <Col sm={12} md={3} lg={3} style={{ padding: "5px" }}>
                    <Form.Group>
                        <Form.Label>Select Shop</Form.Label>
                        <Form.Control
                            as="select"
                            value={filterShopId} onChange={(event) => setFilterShopId(event.target.value)}
                        >
                            <option value="">Select</option>
                            {shops.map((shop, index) => {
                                return <option key={index} value={shop.id}>{shop.name}</option>
                            })
                            }
                        </Form.Control>
                    </Form.Group>
                </Col>

                <Col sm={12} md={3} lg={3} style={{ padding: "5px" }}>
                    <Form.Group>
                        <Form.Label>From Date</Form.Label>
                        <Form.Control
                            type="date"
                            value={shopStartDate}
                            onChange={(event) => setShopStartDate(event.target.value)}
                        />
                    </Form.Group>
                    <Form.Group></Form.Group>

                </Col>
                <Col sm={12} md={3} lg={3} style={{ padding: "5px" }}>
                    <Form.Group>
                        <Form.Label>End Date</Form.Label>
                        <Form.Control
                            type="date"
                            value={shopEndDate}
                            onChange={(event) => setShopEndDate(event.target.value)}
                        />
                    </Form.Group>
                    <Form.Group></Form.Group>

                </Col>
                <Col style={{ padding: "12px",marginTop:"10px" }}>
                    <Button className="btn-custom reset-btn"
                        onClick={resetFilter}
                    >
                        Reset
                    </Button>
                </Col>
            </div>

            </Row>
            <div className="container pt-3">
            {shopRequests.filter((statusRequested) => statusRequested.deliveryStatus === "Delivered" && statusRequested.deliveryPaymentStatus === "Waiting").length === 0&&!flag ?
                <EmptyData />
                :
                shopRequests.filter((statusRequested) => statusRequested.deliveryStatus === "Delivered" && statusRequested.deliveryPaymentStatus === "Waiting").length === 0&&flag?
                <Loading />
                :
                <Table responsive className="table no-wrap">
                    <thead className="table-primary">
                        <tr>
                            <th>Date</th>
                            <th>Customer Detials</th>
                            <th>Assigned</th>
                            <th>Item Details</th>
                            <th>Billing Details</th>
                            <th>Distance/Charge</th>

                        </tr>
                    </thead>

                    <tbody>

                        {shopRequests.filter((statusRequested) => statusRequested.deliveryStatus === "Delivered" && statusRequested.deliveryPaymentStatus === "Waiting").map((request, index) => (
                            <RequestItem key={index} request={request} fetchShopRequest={fetchShopRequest} />
                        ))
                        }

                    </tbody>
                </Table>
            }
            {shopRequests.length !== 0 &&
                <Table responsive className="table no-wrap">
                    <thead className="table-primary">
                        <tr>
                            <th>Total Amount</th>

                            <th>Add Bill</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                {totalAmount}
                            </td>

                            <td>
                                <Button className="btn-custom reset-btn" onClick={() => setShowModal(true)}>Add bill</Button>

                            </td>
                        </tr>
                    </tbody>
                </Table>
            }
            </div>
             </div>
            <ConfirmModal showModal={showModal} closeModal={closeModalHandler} confirmModal={AddShopBill} />
        </>
    )
}

export default AddShopBill;



