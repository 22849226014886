import React, { useState, useEffect } from "react";
import { Button, Modal, Form, Row, Col, Card, Tabs, Tab, Table, Alert } from "react-bootstrap";
import api from "../../config/api";
import { getAdminConfig } from "../../config/requestConfig";
import { successModule } from "../../utils/utils";
import EmptyData from "../common/EmptyData";
import Loading from "../common/Loading";


const DeliveryBoyItem = ({ deliveryBoy, fetchDeliveryBoys }) => {
    const editDeliveryBoy = (event) => {
        const checked = event.target.checked;
        const status =checked===true?"active":"inactive";
        const deliveryBoyId = deliveryBoy.loginId;
        if(deliveryBoyId&&status)
        api.put(`/admin/deliveryBoy/${deliveryBoyId}/${status}`, {}, getAdminConfig()).then((response) => {
            fetchDeliveryBoys();
            successModule("Delivery boy is " + status);
        })
    }
   
    return (
        <>
            <tr>
                <td>{deliveryBoy.name}</td>
                <td>{deliveryBoy.loginId} </td>
                <td>{deliveryBoy.email} </td>

                <td>{deliveryBoy.address} <br />
                    {deliveryBoy.postalOffice} <br />
                    {deliveryBoy.postalCode} <br />
                </td>
                <td>{deliveryBoy.phoneNumber} </td>
                <td>
                    <div>
                        <div className="switch-container">
                            <input className="switch-input" type="checkbox" role="switch" onChange={editDeliveryBoy} checked={deliveryBoy.status === "active" ? true : false} />
                            <label className="switch-label">{deliveryBoy.status === "active" ? "Active" : "Inactive"}</label>
                        </div>
                    </div>
                </td>
            </tr>

        </>
    )
}

const DeliveryBoy = () => {

    const [deliveryBoys, setDeliveryBoys] = useState([]);
    const [flag, setFlag] = useState(true);
    useEffect(() => {
        fetchDeliveryBoys();
    }, [])

    const fetchDeliveryBoys = () => {
        api.get(`/admin/deliveryBoy/`, getAdminConfig()).then((response) => {
            setDeliveryBoys(response.data);
            setFlag(false)
        })
    }

    return (

        <div>
            <div className="container pt-3">
                <div className="d-flex align-items-center justify-content-between mb-3">
                    <div className="admin-header">Delivery Boys</div>
                </div>
                <br />
                {deliveryBoys.length === 0 && !flag ?
                    <EmptyData />
                    :
                    deliveryBoys.length === 0 && flag ?
                        <Loading />
                        :
                        <Table responsive className="table no-wrap">
                            <thead className="table-primary">
                                <tr>
                                    <th>Delivery Boy Name</th>
                                    <th>LoginId</th>
                                    <th>email</th>
                                    <th>address</th>
                                    <th>Phone Number</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    deliveryBoys.length !== 0 && deliveryBoys.map((deliveryBoy, index) => (
                                        <DeliveryBoyItem key={index} deliveryBoy={deliveryBoy} fetchDeliveryBoys={fetchDeliveryBoys} />
                                    ))
                                }
                            </tbody>
                        </Table>
                }
            </div>
        </div>
    )
}
export default DeliveryBoy;