import React, { useState, useEffect } from "react";
import { Button, Modal, Form, Row, Col, Card, Tabs, Tab, Table, Accordion } from "react-bootstrap";
import api from "../../config/api";
import { getAdminConfig } from "../../config/requestConfig";
import EmptyData from "../common/EmptyData";
import Loading from "../common/Loading";



//request detais
const RequestDetails = ({ deliveryPayment, showRequestDetails, closeRequestDetails }) => {

    return (<Modal
        show={showRequestDetails}
        onHide={closeRequestDetails}
        aria-labelledby="contained-modal-title-vcenter"
        centered
    >
        <Modal.Header closeButton>
            <Modal.Title>Request Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Accordion defaultActiveKey="0">
                <Card>
                    {
                        deliveryPayment.requests.map((request, index) => (
                            <>
                                <Accordion.Toggle as={Card.Header} eventKey={index ? index : "0"}>
                                    {request.name}
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey={index ? index : "0"}>
                                    <Card.Body>
                                        <Table className="table table-bordered">

                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <h6>Address :</h6>
                                                        {request.address}<br />
                                                        {request.postalOffice}<br />
                                                        {request.postalCode}<br />
                                                        {request.phoneNumber}<br />
                                                    </td>
                                                    <td>
                                                        <h6>Assigned To :</h6> {request.assignedTo.name}
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td>
                                                        <h6>Item Count :</h6>
                                                        {request.itemCount}
                                                    </td>
                                                    <td>
                                                        <h6>Items :</h6> {request.items.map((item, index) => {
                                                            return item + "; ";
                                                        })}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <h6>Approximate Weight :</h6> {request.weight}
                                                    </td>
                                                    <td>
                                                        <h6>Bill Amount : </h6>Rs. {request.billAmount}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <h6>Payment Type : </h6>{request.paymentType}
                                                    </td>
                                                    <td>
                                                        <h6>Payment Status : </h6>{request.paymentStatus}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <h6>Distance : </h6> {request.distance} KM
                                                    </td>
                                                    <td>
                                                        <h6>Delivery Charge : </h6> {request.deliveryCharge} €
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </>
                        ))
                    }
                </Card>
            </Accordion>

        </Modal.Body>
        <Modal.Footer>
            <Button className="box" onClick={closeRequestDetails}>Close</Button>
        </Modal.Footer>

    </Modal>)
}



const DeliveryPaymentItem = ({ deliveryPayment, fetchDeliveryPayments, status }) => {

    const [showRequestDetails, setShowRequestDetails] = useState(false);

    // request details
    const closeRequestDetails = () => {
        setShowRequestDetails(false);
    }
    const openRequestDetails = () => {
        setShowRequestDetails(true);
    }

    //genderate date
    var today = new Date(deliveryPayment.paidAt),
        // get month in two digit
        month =
            today.getMonth() + 1 < "10"
                ? "0" + (today.getMonth() + 1)
                : today.getMonth() + 1,
        // get month in two digit
        day = today.getDate() < "10" ? "0" + today.getDate() : today.getDate(),
        // get current date
        paidAt = day + "-" + month + "-" + today.getFullYear();
    return (
        <>
            <tr onClick={openRequestDetails}>
                <td>{paidAt}</td>
                <td>{deliveryPayment.requests.length}</td>
                <td>{deliveryPayment.totalAmount} </td>
                <td>{deliveryPayment.serviceCharge} </td>
                <td>{deliveryPayment.grossAmount} </td>
            </tr>
            <RequestDetails
                showRequestDetails={showRequestDetails}
                closeRequestDetails={closeRequestDetails}
                deliveryPayment={deliveryPayment} />
        </>
    )
}

const DeliveryPayment = () => {

    //set delivery payments and delivery boys
    const [deliveryPayments, setDeliveryPayments] = useState([]);
    const [deliveryBoys, setDeliveryBoys] = useState([]);
    const [flag, setFlag] = useState(true);

    //set delivery payment detials
    const [getDeliveryBoy, setGetDeliveryBoy] = useState("");


    //get delivery boys
    useEffect(() => {
        fetchDeliveryBoys();
    }, []);

    //get deliveryboy payment detials
    useEffect(() => {
        fetchDeliveryPayments(getDeliveryBoy);
    }, [getDeliveryBoy]);


    //reset after filter payments
    const resetButton = () => {
        fetchDeliveryPayments("");
        setDeliveryPayments("");
        setGetDeliveryBoy("");
    }

    //fetch all delivery boy payments by delivery boy id
    const fetchDeliveryPayments = (getDeliveryBoy) => {
        api.get(`/admin/deliveryBoyPayment/deliveryBoy/${getDeliveryBoy}`, getAdminConfig()).then((response) => {
            setDeliveryPayments(response.data);
            setFlag(false);
        })
    }

    //fetch all delivery boys
    const fetchDeliveryBoys = () => {
        api.get(`/admin/deliveryBoy/`, getAdminConfig()).then((response) => {
            setDeliveryBoys(response.data);
            setFlag(false);
        })
    }
    return (

        <div>
            <div className="container pt-3">
                <div className="d-flex align-items-center justify-content-between mb-3">
                    <div className="admin-header">Delivery Boy Payments</div>
                </div>
                <br />
                <Row>
                <div className="d-flex hstack gap-2 mt-0">
                    <Col sm={12} md={3} lg={3} style={{ padding: "5px" }}>
                        <Form.Group>
                            <Form.Label>Select Delivery Boy</Form.Label>
                            <Form.Control
                                as="select"
                                value={getDeliveryBoy} onChange={(event) => setGetDeliveryBoy(event.target.value)}
                            >
                                <option value="">Select</option>
                                {deliveryBoys.map((deliveryBoy, index) => {
                                    return <option key={index} value={deliveryBoy.id}>{deliveryBoy.name}</option>
                                })
                                }
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col style={{ padding: "12px",marginTop:"10px" }}>
                        <Button className="btn-custom reset-btn"
                            onClick={resetButton}
                        >
                            Reset
                        </Button>
                    </Col>
                    </div>
                </Row>
                <div className="container pt-3">
                {deliveryPayments.length === 0 && !flag ?
                    <EmptyData />
                    :
                    deliveryPayments.length === 0 && flag ?
                        <Loading />
                        :
                        <Table responsive className="table no-wrap">
                            <thead className="table-primary">
                                <tr>
                                    <th>Payment Date</th>
                                    <th>Request Count</th>
                                    <th>Total Amount</th>
                                    <th>Service Charge</th>
                                    <th>Gross Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    deliveryPayments.length !== 0 && deliveryPayments.map((deliveryPayment, index) => (
                                        <DeliveryPaymentItem key={index} deliveryPayment={deliveryPayment} fetchDeliveryPayments={fetchDeliveryPayments} />
                                    ))
                                }
                            </tbody>
                        </Table>
                }
                </div>
            </div>
        </div>
    )
}
export default DeliveryPayment;