import React, { useState, useEffect } from "react";
import { Button, Modal, Form, Row, Col, Card,Tabs, Tab,Table } from "react-bootstrap";
import api from "../../config/api";
import { getAdminConfig } from "../../config/requestConfig";
import {RequestDetails} from "./RequestDetails";
const Request = () => {

    const [requests, setRequests] = useState([]);
    const [tabKey, setTabKey] = useState("shop");
    var today = new Date(),
    // get month in two digit
    month =
      today.getMonth() + 1 < "10"
        ? "0" + (today.getMonth() + 1)
        : today.getMonth() + 1,
    // get month in two digit
    day = today.getDate() < "10" ? "0" + today.getDate() : today.getDate(),
    // get current date
    date = today.getFullYear() + "-" + month + "-" + day;

    const [filterItem, setFilterItem] = useState("");
    const [startDate, setStartDate] = useState(date);
    const [endDate, setEndDate] = useState(date);

    useEffect(() => {
        fetchRequest(filterItem,startDate,endDate);
    }, [filterItem,startDate,endDate]);


    const fetchRequest = (filter,StartDate,EndDate) => {
        const url = tabKey==="shop"?"shopRequest":"deliveryBoyRequest";
        if(filter!==""&&StartDate!==""&&EndDate!=="")
        {
            api.get(`/admin/${url}/${filter}/${StartDate}/${EndDate}`,getAdminConfig()).then((response) => {
                setRequests(response.data);

            })
        }
    }
  
    return (

        <div>
            <div className="container pt-3">
                <div className="d-flex align-items-center justify-content-between mb-3">
                    <div className="admin-header">Request</div> 
                </div>
        
                <br/>
                <Tabs defaultActiveKey="shop" id="uncontrolled-tab-example" activeKey={tabKey} onSelect={(k) => setTabKey(k)}>
                    <Tab eventKey="shop" title="Shop">
                        <RequestDetails 
                        requests={requests}  
                        setRequests={setRequests}
                        fetchRequest = {fetchRequest} 
                        filterItem = {filterItem} 
                        setFilterItem={setFilterItem} 
                        startDate={startDate} 
                        setStartDate={setStartDate} 
                        endDate={endDate}
                        setEndDate={setEndDate}
                        requestType={tabKey}
                        date = {date}
                        />
                    </Tab>
                    <Tab eventKey="deliveryBoy" title="Delivery Boy">
                        <RequestDetails 
                        requests={requests}  
                        setRequests={setRequests}
                        fetchRequest = {fetchRequest} 
                        filterItem = {filterItem} 
                        setFilterItem={setFilterItem} 
                        startDate={startDate} 
                        setStartDate={setStartDate} 
                        endDate={endDate}
                        setEndDate={setEndDate}
                        requestType={tabKey}
                        date = {date}
                        />
                    </Tab>

                </Tabs>
                </div>
        </div>
    )
}
export default Request;