import React, { useState, useEffect } from "react";
import { Button, Modal, Form, Row, Col, Card, Tabs, Tab, Table } from "react-bootstrap";
import api from "../../config/api";
import { getShopConfig } from "../../config/requestConfig";
import { DeliveryDetails } from "./DeliveryDetails";
import { successModule } from "../../utils/utils";

export const EditRequest = ({ showEditRequest, closeEditRequest, editRequest, request }) => {
    const [paymentType, setPaymentType] = useState(request.paymentType);
    const [paymentStatus, setPaymentStatus] = useState(request.paymentStatus);
    const requestId = request.id;
    const formSubmitHandler = (event) => {
        event.preventDefault();
        editRequest(requestId, paymentType, paymentStatus);
    }

    return (<Modal
        show={showEditRequest}
        onHide={closeEditRequest}
        aria-labelledby="contained-modal-title-vcenter"
        size="xl"
        centered
    >
        <Modal.Header className="pb-3 px-4 pt-4">
            <Modal.Title>Edit Request</Modal.Title>
            <div class="close-container" onClick={closeEditRequest}>
                <div class="close-line1"></div>
                <div class="close-line2"></div>
            </div>
        </Modal.Header>

        <Form onSubmit={formSubmitHandler}>
            <Modal.Body>
                <div className="row justify-content-center">
                    <div className="col-md-6">
                        <Form.Group>
                            <Form.Label>Payment Type</Form.Label>
                            <Form.Control
                                as="select"
                                placeHolder="Payment Type"
                                value={paymentType}
                                onChange={(event) => setPaymentType(event.target.value)}
                                required>
                                <option value="">select</option>
                                <option>Online payment</option>
                                <option>Cash on delivery</option>
                                <option>Prepaid</option>

                            </Form.Control>
                        </Form.Group>
                    </div>
                    <div className="col-md-6">
                        <Form.Group>
                            <Form.Label>Payment Status</Form.Label>
                            <Form.Control
                                as="select"
                                placeHolder="Payment Status"
                                value={paymentStatus}
                                onChange={(event) => setPaymentStatus(event.target.value)}
                                required>
                                <option value="">select</option>
                                <option>Received</option>
                                <option>Waiting</option>
                            </Form.Control>
                        </Form.Group>
                    </div>
                </div>


            </Modal.Body>
            <div className="p-4">
                <Button onClick={closeEditRequest} style={{ marginLeft: "25px" }}
                    className="btn-custom reset-btn">Close</Button>
                <Button className="btn-custom close-btn" type="submit">Update</Button>

            </div>
        </Form>
    </Modal>)
}

const AddRequest = ({ showRequest, closeRequest, addRequest }) => {

    const [name, setName] = useState([]);
    const [address, setAddress] = useState([]);
    const [postalOffice, setPostalOffice] = useState([]);
    const [postalCode, setPostalCode] = useState([]);
    const [phoneNumber, setPhoneNumber] = useState([]);
    const [distance, setDistance] = useState([]);

    const [items, setItems] = useState([]);
    const [itemCount, setItemCount] = useState("");
    const [counts, setCounts] = useState([]);
    const [weight, setWeight] = useState([]);
    const [billAmount, setBillAmount] = useState([]);
    const [paymentType, setPaymentType] = useState([]);
    const [paymentStatus, setPaymentStatus] = useState([]);
    const [deliveryCharge, setDeliveryCharge] = useState([]);
    const [validated, setValidated] = useState(false);

    const itemHandler = (event) => {
        let itms = items;
        let counts = [];
        itms[event.target.name] = event.target.value;
        setItems(itms);
        counts.push(items);
        setCounts(counts);
    }

    useEffect(() => {
        counts.map((count, index) => {
            setItemCount(count.length);
        })
    }, [counts])

    const distanceHandler = (event) => {
        let distance = event.target.value;
        setDistance(distance);
        setDeliveryCharge(distance * 5);
    }

    const formSubmitHandler = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation();
            event.preventDefault();
            setValidated(true);
        }
        else {
            event.preventDefault();
            addRequest(name, address, postalOffice, postalCode, phoneNumber, distance, itemCount, items, weight, billAmount, paymentType, paymentStatus, deliveryCharge);
        }
    }

    return (<Modal
        show={showRequest}
        onHide={closeRequest}
        aria-labelledby="contained-modal-title-vcenter"
        size="xl"
        centered
    >

        <Modal.Header className="pb-3 px-4 pt-4">
            <Modal.Title>Add Request</Modal.Title>
            <div class="close-container" onClick={closeRequest}>
                <div class="close-line1"></div>
                <div class="close-line2"></div>
            </div>
        </Modal.Header>

        <Form noValidate validated={validated} onSubmit={formSubmitHandler} >

            <Modal.Body>
                <div className="row justify-content-center">
                    <div className="col-md-6">
                        <Form.Group>
                            <Form.Label>Customer Name <span className="required">*</span></Form.Label>
                            <Form.Control type="text" value={name} onChange={(event) => setName(event.target.value)} required />
                            <Form.Control.Feedback type="invalid">
                                Customer Name is required
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Phone Number <span className="required">*</span></Form.Label>
                            <Form.Control type="number" value={phoneNumber} onChange={(event) => setPhoneNumber(event.target.value)} required />
                            <Form.Control.Feedback type="invalid">
                                Phone Number is required
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Distance(KM) <span className="required">*</span></Form.Label>
                            <Form.Control type="number" value={distance} onChange={distanceHandler} required />
                            <Form.Control.Feedback type="invalid">
                                Distance is required
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>Items (Maximum 1 item is required) <span className="required">*</span></Form.Label>
                            <Table className="table table-bordered">

                                <thead>
                                    <tr>
                                        <th>Items</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>

                                            <Form.Control
                                                type="text"
                                                placeholder="item 1"
                                                value={items[0]}
                                                name={0}
                                                onChange={itemHandler}
                                                required />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide 1 item is required
                                            </Form.Control.Feedback>

                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <Form.Control
                                                type="text"
                                                placeholder="item 2"
                                                value={items[1]}
                                                name={1}
                                                onChange={itemHandler}

                                            />

                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <Form.Control
                                                type="text"
                                                placeholder="item 3"
                                                value={items[2]}
                                                name={2}
                                                onChange={itemHandler}
                                            />

                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <Form.Control
                                                type="text"
                                                placeholder="item 4"
                                                value={items[3]}
                                                name={3}
                                                onChange={itemHandler}

                                            />

                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <Form.Control
                                                type="text"
                                                placeholder="item 5"
                                                value={items[4]}
                                                name={4}
                                                onChange={itemHandler}

                                            />

                                        </td>
                                    </tr>

                                </tbody>
                            </Table>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Payment Type <span className="required">*</span></Form.Label>
                            <Form.Control
                                as="select"
                                placeHolder="Payment Type"
                                value={paymentType}
                                onChange={(event) => setPaymentType(event.target.value)}
                                required>
                                <option value="">select</option>
                                <option>Online payment</option>
                                <option>Cash on delivery</option>
                                <option>Prepaid</option>

                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                                Payment is required
                            </Form.Control.Feedback>
                        </Form.Group>
                    </div>
                    <div className="col-md-6">
                        <Form.Group>
                            <Form.Label>Customer Address <span className="required">*</span></Form.Label>
                            <Form.Control type="textarea" value={address} onChange={(event) => setAddress(event.target.value)} required />
                            <Form.Control.Feedback type="invalid">
                                Customer Address is required
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Postal Office <span className="required">*</span></Form.Label>
                            <Form.Control type="text" value={postalOffice} onChange={(event) => setPostalOffice(event.target.value)} required />
                            <Form.Control.Feedback type="invalid">
                                Postal Office is required
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Postal Code <span className="required">*</span></Form.Label>
                            <Form.Control type="number" value={postalCode} onChange={(event) => setPostalCode(event.target.value)} required />
                            <Form.Control.Feedback type="invalid">
                                Postal Code is required
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Item Count</Form.Label>
                            <Form.Control type="number" value={itemCount} placeHolder="Item Count" onChange={(event) => setItemCount(event.target.value)} required readOnly />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Approximate Weight <span className="required">*</span></Form.Label>
                            <Form.Control type="number" value={weight} onChange={(event) => setWeight(event.target.value)} required />
                            <Form.Control.Feedback type="invalid">
                                Approximate Weight is required
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Bill Amount <span className="required">*</span></Form.Label>
                            <Form.Control type="number" value={billAmount} onChange={(event) => setBillAmount(event.target.value)} required />
                            <Form.Control.Feedback type="invalid">
                                Bill Amount is required
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Payment Status <span className="required">*</span></Form.Label>
                            <Form.Control
                                as="select"
                                placeHolder="Payment Status"
                                value={paymentStatus}
                                onChange={(event) => setPaymentStatus(event.target.value)}
                                required>
                                <option value="">select</option>
                                <option>Received</option>
                                <option>Waiting</option>
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                                Payment Status is required
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Delivery Charge</Form.Label>
                            <Form.Control type="number" value={deliveryCharge} placeHolder="delivery charge" onChange={(event) => setDeliveryCharge(event.target.value)} required readOnly />
                        </Form.Group>

                    </div>
                </div>
            </Modal.Body>
            <div className="p-4">
                <Button className="btn-custom reset-btn" type="submit">Add</Button>
                <Button onClick={closeRequest} className="btn-custom close-btn">close</Button>
            </div>
        </Form>
    </Modal>)
}

const Request = () => {

    const [requests, setRequests] = useState([]);
    const [showRequest, setShowRequest] = useState(false);
    const [key, setKey] = useState("Requested")
    const [flag, setFlag] = useState(true)
    var today = new Date(),
        // get month in two digit
        month =
            today.getMonth() + 1 < "10"
                ? "0" + (today.getMonth() + 1)
                : today.getMonth() + 1,
        // get month in two digit
        day = today.getDate() < "10" ? "0" + today.getDate() : today.getDate(),
        // get current date
        date = today.getFullYear() + "-" + month + "-" + day;

    const [shopStartDate, setShopStartDate] = useState(date);
    const [shopEndDate, setShopEndDate] = useState(date);

    useEffect(() => {
        fetchRequest(shopStartDate, shopEndDate);
    }, [shopStartDate, shopEndDate])

    const fetchRequest = (shopStartDate, shopEndDate) => {
        api.get(`/shop/deliveryRequest/${shopStartDate}/${shopEndDate}`, getShopConfig()).then((response) => {
            setRequests(response.data);
            setFlag(false)
        })
    }
    const closeRequest = () => {
        setShowRequest(false);
    }
    const openRequest = () => {
        setShowRequest(true);
    }

    const addRequest = (name, address, postalOffice, postalCode, phoneNumber, distance, itemCount, items, weight, billAmount, paymentType, paymentStatus, deliveryCharge) => {

        let content = { name, address, postalOffice, postalCode, phoneNumber, distance, itemCount, items, weight, billAmount, paymentType, paymentStatus, deliveryCharge };
        api.post(`/shop/deliveryRequest`, content, getShopConfig()).then((response) => {
            setShowRequest(false);
            successModule("Delivery Item added Successfully");
            fetchRequest(shopStartDate, shopEndDate);
        })

    }

    const resetFilter = () => {
        setShopStartDate(date);
        setShopEndDate(date);
    }
    return (

        <div>
            <div className="container pt-3">
                <div className="d-flex align-items-center justify-content-between mb-3">
                    <div className="admin-header">
                        Request
                    </div>
                    <Button onClick={openRequest} className="btn-custom px-4">
                        Add Request
                    </Button>
                </div>
                <br />
                        <Row className="gap-2 align-items-center">
                                <Col sm={12} md={3} lg={3} style={{ padding: "5px" }}>
                                    <Form.Group>
                                        <Form.Label>From Date</Form.Label>
                                        <Form.Control
                                            type="date"
                                            value={shopStartDate}
                                            onChange={(event) => setShopStartDate(event.target.value)}
                                        />
                                    </Form.Group>
                                    <Form.Group></Form.Group>

                                </Col>
                                <Col sm={12} md={3} lg={3} style={{ padding: "5px" }}>
                                    <Form.Group>
                                        <Form.Label>End Date</Form.Label>
                                        <Form.Control
                                            type="date"
                                            value={shopEndDate}
                                            onChange={(event) => setShopEndDate(event.target.value)}
                                        />
                                    </Form.Group>
                                    <Form.Group></Form.Group>

                                </Col>
                                <Col style={{ padding: "12px", marginTop: "10px" }}>
                                    <Button className="btn-custom reset-btn"
                                        variant="dark"
                                        onClick={resetFilter}
                                    >
                                        Reset
                                    </Button>
                                </Col>
                        </Row>
                        <br />
                        <Tabs defaultActiveKey="requested" id="uncontrolled-tab-example" activeKey={key} onSelect={(k) => setKey(k)}>
                            <Tab
                                eventKey="Requested"
                                title={"Requested (" + requests.filter((request) => request.deliveryStatus === "Requested").length + ")"}
                            >
                                <DeliveryDetails status={key} requests={requests} fetchRequest={fetchRequest} startDate={shopStartDate} endDate={shopEndDate} flag={flag} />
                            </Tab>
                            <Tab
                                eventKey="Assigned"
                                title={"Assigned (" + requests.filter((request) => request.deliveryStatus === "Assigned").length + ")"}
                            >
                                <DeliveryDetails status={key} requests={requests} fetchRequest={fetchRequest} startDate={shopStartDate} endDate={shopEndDate} flag={flag} />
                            </Tab>
                            <Tab
                                eventKey="Pickedup"
                                title={"Pickedup (" + requests.filter((request) => request.deliveryStatus === "Pickedup").length + ")"}
                            >
                                <DeliveryDetails status={key} requests={requests} fetchRequest={fetchRequest} startDate={shopStartDate} endDate={shopEndDate} flag={flag} />
                            </Tab>
                            <Tab
                                eventKey="Delivered"
                                title={"Delivered (" + requests.filter((request) => request.deliveryStatus === "Delivered").length + ")"}
                            >
                                <DeliveryDetails status={key} requests={requests} fetchRequest={fetchRequest} startDate={shopStartDate} endDate={shopEndDate} flag={flag} />
                            </Tab>
                        </Tabs>
                <AddRequest
                    showRequest={showRequest}
                    closeRequest={closeRequest}
                    addRequest={addRequest}
                />
            </div>
        </div>
    )
}
export default Request;