import React, { useState, useEffect } from "react";
import { Row, Col, Card, Form, Button, Alert, Tabs, Tab } from "react-bootstrap";
import api from '../../config/api';
import { Link, BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { errorMessage } from "../../utils/utils";
import LoginPage from "../common/LoginPage";
import { LoginIcon } from "../svgs";
const Login = ({ setAdmin, props }) => {

 
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [validated, setValidated] = useState(false);
  //login submit
  const onSumbitLoginHandler = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      event.preventDefault();
      setValidated(true);
    }
    else {
      event.preventDefault();
      let content = { username, password }
      api.post("/admin/login", content).then((res) => {
        let loggedAdmin = JSON.stringify(res.data);
        localStorage.setItem("loggedAdmin", loggedAdmin);
        setAdmin(loggedAdmin);
      }).catch((err) => {
        errorMessage("Invalid userId or password")

      })
    }
  }

  return (
    <>
      <Row>
        <Col lg={8} className="left-section">
          <img
            src={process.env.PUBLIC_URL + "/images/logo.png"}
            className="emblem"
          />
        </Col>
        <Col lg={4} className="right-section">
         
            <div className="header-name"><LoginIcon stroke='#404040' strokeWidth={2}/> ADMIN LOGIN</div>
         
          <LoginPage
            userId={username}
            password={password}
            setUserId={setUserName}
            setPassword={setPassword}
            submitHandler={onSumbitLoginHandler}
            validated={validated}
          />
        </Col>
      </Row>

    </>
  );
};
export default Login;
