import React,{useState} from "react";
import { useHistory } from "react-router-dom";
import {sidebarShow } from "../../utils/utils";
import { Logout } from "../svgs/Logout";
const Header = ({ admin,setAdmin}) => {
    const [sidemenu, setSidemenu]=useState(false);
    let name = (JSON.parse(admin).name);
    const history = useHistory();
    const sideMenuHandler=(flag)=>{
      setSidemenu(flag)
      sidebarShow(flag)
    }
    
    const handleSignout = () => {
      localStorage.removeItem("loggedAdmin");
      setAdmin("");
      history.push("/admin");
  }
  return (
    <>
   
    <header className="container py-2 header-container">
    <div className="d-flex h-100 align-items-center justify-content-between">
    <div className="header-name px-3 py-3">Welcome {name}</div>
    <div className="d-flex h-100 justify-content-end align-items-center">
      <div className="menu-toggle" onClick={()=>sideMenuHandler(sidemenu?false:true)}><i  className={`fa fa-bars`} aria-hidden="true" ></i></div>
      <div className="hstack gap-2 px-3 menu-container"  onClick={handleSignout}><Logout stroke='#404040' strokeWidth={1} /> <div className="app-label"> Sign Out</div></div>
    </div>
    </div>
    </header>
    </>
  );
};
export default Header;
