import React from "react";
import { Link, Route, Switch,useLocation } from "react-router-dom";
import { Nav, Row, Col } from "react-bootstrap";
import Header from "./Header";
import Home from "./Home";
import Request from "./Request";
import Bill from "./Bill";
import { HomeIcon,RequestIcon,CreditCard } from "../svgs";
const Dashboard = ({ shop, setShop }) => {
    const {pathname} = useLocation();

    return (
        <>
            <Header shop={shop} setShop={setShop} />
            <div className="admin-layout-container">
                <div className="d-flex">
                    <div className="sidebar" id="side-bar">
                        <Nav defaultActiveKey="/shop" className="side-menu">
                            <Link to="/shop" className={`text ${pathname === "/shop"?'active':''}`}>
                                    <HomeIcon stroke='#404040' strokeWidth={1}  />
                                    <span>Home</span>
                            </Link>
                            <Link to="/shop/request" className={`text ${pathname === "/shop/request"?'active':''}`}>
                                <RequestIcon stroke='#404040' strokeWidth={1}/>
                                <span>Request</span>
                            </Link>
                            <Link to="/shop/bill" className={`text ${pathname === "/shop/bill"?'active':''}`}>
                                <CreditCard stroke='#404040' strokeWidth={1}/>
                                <span>Bill</span>
                            </Link>

                        </Nav>
                    </div>
                    <section id="section">
                        <Switch>
                            <Route exact path="/shop/" component={Home} />
                            <Route exact path="/shop/request" component={Request} />
                            <Route exact path="/shop/bill" component={Bill} />
                        </Switch>
                    </section>
                </div>
            </div>
        </>

    );
}
export default Dashboard;