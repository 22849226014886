import React from 'react'
import {Link} from 'react-router-dom'

const NotFound = () => {

    return (
     
        <div className="container" style={{ textAlign: "center",marginTop:"20px"}}>
            <img
                src={process.env.PUBLIC_URL + "/images/404_animation.gif"}
                className="not-found"
            />
            <br/>
            <br/>
            <Link
                to={"/"}
                className="btn-custom text-white"
            >
                Go to Home
            </Link>

        </div>
    )
}

export default NotFound;