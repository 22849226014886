import React, { useState, useEffect } from "react";
import { Button, Modal, Form, Row, Col, Card, Tabs, Tab, Table, Accordion } from "react-bootstrap";
import api from "../../config/api";
import { getShopConfig } from "../../config/requestConfig";
import EmptyData from "../common/EmptyData";
import Loading from "../common/Loading";


const PayBill = ({ showPayBill, closePayBill, payBill, bill }) => {

    const [paymentReference, setPaymentReference] = useState([]);
    const [totalAmount, setTotalAmount] = useState(bill.totalAmount);
    const shobBillId = bill.id;
    const [shopId, setShopId] = useState([])

    useEffect(() => {
        let shopId = [];
        bill.requests.map((request, index) => {
            shopId[index] = request.id;
        })
        setShopId(shopId);
    }, [])

    const formSubmitHandler = (event) => {
        event.preventDefault();

        payBill(shobBillId, paymentReference, shopId);
    }

    return (<Modal
        show={showPayBill}
        onHide={closePayBill}
        aria-labelledby="contained-modal-title-vcenter"
        centered
    >
        <Modal.Header className="pb-3 px-3 pt-4">
            <Modal.Title>Pay bill</Modal.Title>
            <div class="close-container" onClick={closePayBill}>
                <div class="close-line1"></div>
                <div class="close-line2"></div>
            </div>
        </Modal.Header>

        <Form onSubmit={formSubmitHandler} encType="multipart/form-data">
            <Modal.Body>
                <Form.Group>
                    <Form.Label>Total Amount</Form.Label>
                    <Form.Control type="number" value={totalAmount} onChange={(event) => setTotalAmount(event.target.value)} required readOnly />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Payment Reference</Form.Label>
                    <Form.Control type="text" placeHolder="payment Reference" onChange={(event) => setPaymentReference(event.target.value)} required />
                </Form.Group>
            </Modal.Body>
            <div className="p-4">

                <Button onClick={closePayBill} className="btn-custom reset-btn">close</Button>
                <Button className="btn-custom close-btn" type="submit">Pay</Button>
            </div>

        </Form>
    </Modal>)
}

//request detais
const RequestDetails = ({ bill, showRequestDetails, closeRequestDetails }) => {

    return (<Modal
        show={showRequestDetails}
        onHide={closeRequestDetails}
        aria-labelledby="contained-modal-title-vcenter"
        centered
    >
        <Modal.Header closeButton>
            <Modal.Title>Request Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Accordion defaultActiveKey="0">
                <Card>
                    {
                        bill.requests.map((request, index) => (
                            <>
                                <Accordion.Toggle as={Card.Header} eventKey={index ? index : "0"}>
                                    {request.name}
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey={index ? index : "0"}>
                                    <Card.Body>
                                        <Table className="table table-bordered">

                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <h6>Address :</h6>
                                                        {request.address}<br />
                                                        {request.postalOffice}<br />
                                                        {request.postalCode}<br />
                                                        {request.phoneNumber}<br />
                                                    </td>
                                                    <td>
                                                        <h6>Assigned To :</h6> {request.assignedTo.name}
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td>
                                                        <h6>Item Count :</h6>
                                                        {request.itemCount}
                                                    </td>
                                                    <td>
                                                        <h6>Items :</h6> {request.items.map((item, index) => {
                                                            return item + "; ";
                                                        })}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <h6>Approximate Weight :</h6> {request.weight}
                                                    </td>
                                                    <td>
                                                        <h6>Bill Amount : </h6>Rs. {request.billAmount}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <h6>Payment Type : </h6>{request.paymentType}
                                                    </td>
                                                    <td>
                                                        <h6>Payment Status : </h6>{request.paymentStatus}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <h6>Distance : </h6> {request.distance} KM
                                                    </td>
                                                    <td>
                                                        <h6>Delivery Charge : </h6> {request.deliveryCharge} €
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </>
                        ))
                    }
                </Card>
            </Accordion>

        </Modal.Body>
        <Modal.Footer>
            <Button className="box" onClick={closeRequestDetails}>Close</Button>
        </Modal.Footer>

    </Modal>)
}

//bill items
const BillItem = ({ bill, fetchBill }) => {


    const [showPayBill, setShowPayBill] = useState(false);
    const [showRequestDetails, setShowRequestDetails] = useState(false);
    const [totalAmount, setTotalAmount] = useState("");
    const closePayBill = () => {
        setShowPayBill(false);
    }
    const openPayBill = () => {
        setShowPayBill(true);
    }
    const payBill = (shobBillId, paymentReference, shopId) => {

        let content = { paymentReference, shopId }
        api.put(`/shop/bill/${shobBillId}`, content, getShopConfig()).then((response) => {
            fetchBill();
        })
    }

    useEffect(() => {
        let totalAmount = 0;
        bill.requests.map((requestAmount, index) => {
            totalAmount = totalAmount + requestAmount.totalAmount
        })
        setTotalAmount(totalAmount);
    })

    //request details
    const closeRequestDetails = () => {
        setShowRequestDetails(false);
    }
    const openRequestDetails = () => {
        setShowRequestDetails(true);
    }


    //createdAt
    var today = new Date(bill.createdAt),
        month =
            today.getMonth() + 1 < "10"
                ? "0" + (today.getMonth() + 1)
                : today.getMonth() + 1,
        day = today.getDate() < "10" ? "0" + today.getDate() : today.getDate(),
        createdAt = day + "-" + month + "-" + today.getFullYear();

    //recievedAt 
    var today = new Date(bill.recievedAt),
        month =
            today.getMonth() + 1 < "10"
                ? "0" + (today.getMonth() + 1)
                : today.getMonth() + 1,
        day = today.getDate() < "10" ? "0" + today.getDate() : today.getDate(),
        recievedAt = day + "-" + month + "-" + today.getFullYear();

    return (
        <>

            <tr >
                {bill.paymentStatus === "Requested" ?
                    <td onClick={openRequestDetails}>{createdAt} </td> :
                    <td onClick={openRequestDetails}>{recievedAt} </td>
                }
                <td onClick={openRequestDetails}>{bill.totalAmount} </td>

                {bill.paymentStatus === "Requested" ?
                    <td><Button className="btn-custom reset-btn" onClick={openPayBill}>Pay</Button></td> : <td>{bill.paymentReference}</td>
                }
            </tr>
            <PayBill
                showPayBill={showPayBill}
                closePayBill={closePayBill}
                payBill={payBill}
                bill={bill}
            />
            <RequestDetails
                showRequestDetails={showRequestDetails}
                closeRequestDetails={closeRequestDetails}
                bill={bill}

            />
        </>
    )
}

const Bill = () => {

    const [bills, setBills] = useState([]);
    const [flag, setFlag] = useState(true);
    const [key, setKey] = useState("Requested")
    useEffect(() => {
        fetchBill();
    }, [])

    const fetchBill = () => {
        api.get(`/shop/bill`, getShopConfig()).then((response) => {
            setBills(response.data);
            setFlag(false);
        })
    }


    return (

        <div>
            <div className="container pt-3">
                <div className="d-flex align-items-center justify-content-between mb-3">
                    <div className="admin-header">Bills</div>
                </div>
                <br />

                <Tabs defaultActiveKey="requested" id="uncontrolled-tab-example" activeKey={key} onSelect={(k) => setKey(k)}>
                    <Tab eventKey="Requested" title={"Requested (" + bills.filter((filterRequest) => filterRequest.paymentStatus === "Requested").length + ")"}>
                        {bills.filter((filterRequest) => filterRequest.paymentStatus === key).length === 0 && !flag ?
                            <EmptyData />
                            :
                            bills.filter((filterRequest) => filterRequest.paymentStatus === key).length === 0 && flag ?
                                <Loading />
                                :
                                <Table responsive className="table no-wrap">
                                    <thead className="table-primary">
                                        <tr>
                                            <th className="text-truncate">Date</th>
                                            <th className="text-truncate">Total Amount</th>
                                            <th className="text-truncate">Pay bill</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            bills.length !== 0 && bills.filter((filterRequest) => filterRequest.paymentStatus === key).map((bill, index) => (
                                                <BillItem key={index} bill={bill} fetchBill={fetchBill} />
                                            ))
                                        }
                                    </tbody>
                                </Table>
                        }
                    </Tab>
                    <Tab eventKey="Paid" title={"Paid (" + bills.filter((filterRequest) => filterRequest.paymentStatus === "Paid").length + ")"}>
                        {bills.filter((filterPaid) => filterPaid.paymentStatus === key).length === 0 ?
                            <EmptyData />
                            :
                            <Table responsive className="table no-wrap">
                                <thead className="table-primary">
                                    <tr>
                                        <th className="text-truncate">Date</th>
                                        <th className="text-truncate">Total Amount</th>
                                        <th className="text-truncate">Payment Reference</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        bills.length !== 0 && bills.filter((filterPaid) => filterPaid.paymentStatus === key).map((bill, index) => (
                                            <BillItem key={index} bill={bill} fetchBill={fetchBill} />
                                        ))
                                    }
                                </tbody>
                            </Table>
                        }
                    </Tab>

                </Tabs>

            </div>
        </div>
    )
}
export default Bill;