import React from 'react';
import logo from './logo.svg';
import { BrowserRouter as Router,Route,Switch } from "react-router-dom";

import './App.css';
import User from "./components/UserLogin";
import Shop from "./components/ShopLogin";
import Admin from "./components/AdminLogin";
import ActivationShop from "./components/shop/ActivationPage";
import ActivationUser from "./components/user/ActivationPage";
import Home from "./Home";
import NotFound from './NotFound';
const App =() =>{
  return (
    <Router>
          <Switch>
            <Route exact path="/" component={Home}/>
            <Route path="/admin" component={Admin}/>
            <Route path="/shop" component={Shop}/>
            <Route path="/delivery-boy" component={User}/>
            <Route
              exact
              path="/activate/shop/:loginId/:activationCode"
              component={ActivationShop}
            />
            <Route
              exact
              path="/activate/user/:loginId/:activationCode"
              component={ActivationUser}
            />
            <Route path="*" component={NotFound} />
          </Switch>
    </Router>
  );
}

export default App;
