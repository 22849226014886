import React from "react";
import { Form, Button } from "react-bootstrap";
const LoginPage = (props) => {
    return (
        <Form className="py-4" noValidate validated={props.validated} onSubmit={props.submitHandler}>
            <Form.Group>
                <Form.Label>User Id <span className="required">*</span></Form.Label>
                <Form.Control type="text" value={props.userId} onChange={(event) => props.setUserId(event.target.value)} required />
                <Form.Control.Feedback type="invalid">
                    User name is required
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
                <Form.Label>Password <span className="required">*</span></Form.Label>
                <Form.Control type="password" value={props.password} onChange={(event) => props.setPassword(event.target.value)} required />
                <Form.Control.Feedback type="invalid">
                    Password is required
                </Form.Control.Feedback>
            </Form.Group>
            <br />

            {props.forgetPassword &&
                <Button variant="link" style={{ float: "left" }} className="text-dark" onClick={props.forgetPassword}>Forget Password ?</Button>}
            <Button className="btn-custom close-btn" type="submit">Login</Button>
        </Form>
    )
}

export default LoginPage;