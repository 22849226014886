import React, { useState } from "react";
import Login from "./user/Login";
import Dashboard from "./user/Dashboard";
const User = () => {
    const [user, setUser] = useState(localStorage.getItem("loggedUser"));

    if (user)
        return <Dashboard user={user} setUser={setUser}/>;
    else
        return <Login setUser={setUser} />;

}
export default User;