import React from "react";
import Header from "./Header";
import Home from "./Home";
const Dashboard = ({ user, setUser }) => {
    return (
        <>
        <Header user={user} setUser={setUser}/>
        <Home user={user}/>
      {/* <Row>
      <Col lg={2} className="side-nav">
                <Nav defaultActiveKey="/user/" className="flex-column">
                    <Link to="/user" className="text">
                        <i className="fa fa-home"></i>
                        <span>Home</span>
                    </Link>
                    <Link to="/user/myRequest" className="text">
                        <i className="fa fa-paper-plane"></i>
                        <span>My Request</span>
                    </Link>
                    <Link to="/user/payment" className="text">
                        <i className="fa fa-paper-plane"></i>
                        <span>Payment</span>
                    </Link>
                  
                    <Link to="/user" className="text" onClick={handleSignout}>
                        <i className="fa fa-sign-out"></i>
                        <span>Sign out</span>
                    </Link>

                </Nav>
        </Col>
        <Col lg={10} className="main">
                <Switch>
                    <Route exact path="/user/" component={Home} />
                    <Route exact path="/user/myRequest" component={MyRequest} />
                    <Route exact path="/user/payment" component={Payment} />
                </Switch>
                </Col>
      </Row> */}
    </>

    );
}
export default Dashboard;