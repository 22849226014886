import React, { useState } from "react";
import Login from "./admin/Login";
import Dashboard from "./admin/Dashboard";
const User = () => {
    const [admin, setAdmin] = useState(localStorage.getItem("loggedAdmin"));

    if (admin)
        return <Dashboard admin={admin} setAdmin={setAdmin}/>;
    else
        return <Login setAdmin={setAdmin} />;

}
export default User;