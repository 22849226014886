import React, { useState, useEffect } from "react";
import { Row, Col, Card, Form, Button, Alert, Tabs, Tab } from "react-bootstrap";
import { Link } from "react-router-dom";
import api from "../../config/api";
import { successModule } from "../../utils/utils";
const ActivationPage = (props) => {
  const [status, setStatus] = useState(false);
  useEffect(() => {
    fetchShops();
  }, []);

  const fetchShops = () => {
    api.get(`/shop/activate/${props.match.params.loginId}/${props.match.params.activationCode}`).then((response) => {
      setStatus(true);
    });
  };
  return (
    <div className="container" style={{ textAlign: "center" }}>
      <img
        src={process.env.PUBLIC_URL + "/images/logo.png"}
        className="emblem"
      />
      <br />
      {!status ? <h4>Your Account is Activating...</h4>
        :
        <>
          <h4><img src={process.env.PUBLIC_URL + "/images/tick.png"}
            height={60}
            width={60}
          /> Your Account is Activated</h4>
          <Link
            to={"/user"}
            className="btn-custom text-white"
          >
            Click Here to Login
          </Link>
          <br />
          <br />
        </>
      }

    </div>
  );
};

export default ActivationPage;
