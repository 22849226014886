import React, { useState } from "react";
import Login from "./shop/Login";
import Dashboard from "./shop/Dashboard";
const Shop = () => {
    const [shop, setShop] = useState(localStorage.getItem("loggedShop"));

    if (shop)
        return <Dashboard shop={shop} setShop={setShop}/>;
    else
        return <Login setShop={setShop} />;


}
export default Shop;