import React, { useState, useEffect } from "react";
import { Button, Modal, Form, Row, Col, Card, Tabs, Tab, Table, Alert } from "react-bootstrap";
import api from "../../config/api";
import { getAdminConfig } from "../../config/requestConfig";
import { errorMessage, successModule } from "../../utils/utils";
import ConfirmModal from "../common/ConfirmModal";
import EmptyData from "../common/EmptyData";
import Loading from "../common/Loading";
const RequestItem = ({ request, fetchRequest }) => {

    
    var today = new Date(request.requestedAt),
        // get month in two digit
        month =
            today.getMonth() + 1 < "10"
                ? "0" + (today.getMonth() + 1)
                : today.getMonth() + 1,
        // get month in two digit
        day = today.getDate() < "10" ? "0" + today.getDate() : today.getDate(),
        // get current date
        requestedAt = day + "-" + month + "-" + today.getFullYear();
    return (
        <>
            <tr>
                <td>{requestedAt}</td>
                <td>{request.shop.name}<br />{request.shop.address}<br />{request.shop.postalOffice}<br />{request.shop.postalCode}<br />{request.shop.phoneNumber}</td>
                <td>{request.name}<br />{request.address}<br />{request.postalOffice}<br />{request.postalCode}<br />{request.phoneNumber} </td>
                <td>Item Count : {request.itemCount}<br />
                    Items : <br />{request.items.map((item, index) => {
                        return item + "; ";
                    })} <br /> Approximate weight : {request.weight} KG
                </td>
                <td>Bill Amount : {request.billAmount}<br /> Payment Type : <br /> {request.paymentType} <br /> Payment status : {request.paymentStatus}  </td>

                <td>Distance : {request.distance} KM <br /> Charge : {request.deliveryCharge} € </td>


            </tr>
        </>
    )
}

const AddDeliveryPayment = () => {

    const [deliveryBoyRequests, setDeliveryBoyRequests] = useState([]);
    const [deliveryBoys, setDeliveryBoys] = useState([]);
    const [flag, setFlag] = useState(true);

    var today = new Date(),
        // get month in two digit
        month =
            today.getMonth() + 1 < "10"
                ? "0" + (today.getMonth() + 1)
                : today.getMonth() + 1,
        // get month in two digit
        day = today.getDate() < "10" ? "0" + today.getDate() : today.getDate(),
        // get current date
        date = today.getFullYear() + "-" + month + "-" + day;

    const [filterDeliveryBoy, setFilterDeliveryBoy] = useState([]);
    const [deliveryBoyStartDate, setDeliveryBoyStartDate] = useState(date);
    const [deliveryBoyEndDate, setDeliveryBoyEndDate] = useState(date);
    const [showModal, setShowModal] = useState(false)
    const [paymentObj, setPaymentObj] = useState({ totalAmount: 0, serviceCharge: 0, grossAmount: 0 })

    useEffect(() => {
        fetchDeliveryBoyRequest(filterDeliveryBoy, deliveryBoyStartDate, deliveryBoyEndDate);
    }, [filterDeliveryBoy, deliveryBoyStartDate, deliveryBoyEndDate]);

    //get delivery boys
    useEffect(() => {
        fetchDeliveryBoys();
    }, []);
    //get delivery boy request detials by filter
    const fetchDeliveryBoyRequest = (filterDeliveryBoy, deliveryBoyStartDate, deliveryBoyEndDate) => {
        if (filterDeliveryBoy !== "" && deliveryBoyStartDate !== "" && deliveryBoyEndDate !== "") {
            api.get(`/admin/deliveryBoyRequest/${filterDeliveryBoy}/${deliveryBoyStartDate}/${deliveryBoyEndDate}`, getAdminConfig()).then((response) => {
                //    setDeliveryBoyRequests(response.data);
                setDeliveryBoyRequests(response.data.filter((fitlerData) => fitlerData.deliveryStatus === "Delivered" && fitlerData.deliveryPaymentStatus !== "Settled to deliveryboy"));

            })
        }

    }

    useEffect(() => {
        if (deliveryBoyRequests.length !== 0)
            for (const [i, deliveryBoyRequest] of deliveryBoyRequests.entries()) {
                setPaymentObj(prevState => ({
                    ...prevState,
                    totalAmount: (Number(prevState.totalAmount) + Number(deliveryBoyRequest.deliveryCharge)),
                    serviceCharge: (prevState.totalAmount + Number(deliveryBoyRequest.deliveryCharge / 10)),
                    grossAmount: (prevState.totalAmount + Number(deliveryBoyRequest.deliveryCharge - prevState.totalAmount + deliveryBoyRequest.deliveryCharge / 10))
                }));
            }
    }, [deliveryBoyRequests])


    const resetFilter = () => {
        fetchDeliveryBoyRequest("");
        setFilterDeliveryBoy("");
        setDeliveryBoyStartDate(date);
        setDeliveryBoyEndDate(date);
        setDeliveryBoyRequests([])
    }

    const closeModalHandler = () => {
        setShowModal(false)
    }
    //add payment
    const addPayment = () => {
        const totalAmount = paymentObj.totalAmount;
        const serviceCharge = paymentObj.serviceCharge;
        const grossAmount = paymentObj.grossAmount;
        console.log(deliveryBoyRequests);
        let content = { "deliveryBoy": filterDeliveryBoy, requests: deliveryBoyRequests.map((deliveryBoyRequest) => deliveryBoyRequest.id), totalAmount, serviceCharge, grossAmount }
        api.post(`/admin/deliveryBoyPayment`, content, getAdminConfig()).then((response) => {
            console.log(response)
            setDeliveryBoyRequests("");
            setShowModal(false);
            successModule("Successfully Paid to delivery boy");
            resetFilter();

        }).catch((ex) => {
            errorMessage("Not updated")
        })
    }

    //fetch all delivery boys
    const fetchDeliveryBoys = () => {
        api.get(`/admin/deliveryBoy/`, getAdminConfig()).then((response) => {
            setDeliveryBoys(response.data);
            setFlag(false);
        })
    }

    return (

        <>
            <div className="container pt-3">
                <div className="d-flex align-items-center justify-content-between mb-3">
                    <div className="admin-header">Add Payments</div>
                </div>

                <br />
                <Row>
                    <div className="d-flex hstack gap-2 mt-0">
                        <Col sm={12} md={3} lg={3} style={{ padding: "5px" }}>
                            <Form.Group>
                                <Form.Label>Select Delivery Boy</Form.Label>
                                <Form.Control
                                    as="select"
                                    value={filterDeliveryBoy} onChange={(event) => setFilterDeliveryBoy(event.target.value)}
                                >
                                    <option value="">Select</option>
                                    {deliveryBoys.map((deliveryBoy, index) => {
                                        return <option key={index} value={deliveryBoy.id}>{deliveryBoy.name}</option>
                                    })
                                    }
                                </Form.Control>
                            </Form.Group>
                        </Col>

                        <Col sm={12} md={3} lg={3} style={{ padding: "5px" }}>
                            <Form.Group>
                                <Form.Label>From Date</Form.Label>
                                <Form.Control
                                    type="date"
                                    value={deliveryBoyStartDate}
                                    onChange={(event) => setDeliveryBoyStartDate(event.target.value)}
                                />
                            </Form.Group>
                            <Form.Group></Form.Group>

                        </Col>
                        <Col sm={12} md={3} lg={3} style={{ padding: "5px" }}>
                            <Form.Group>
                                <Form.Label>End Date</Form.Label>
                                <Form.Control
                                    type="date"
                                    value={deliveryBoyEndDate}
                                    onChange={(event) => setDeliveryBoyEndDate(event.target.value)}
                                />
                            </Form.Group>
                            <Form.Group></Form.Group>

                        </Col>
                        <Col style={{ padding: "12px", marginTop: "10px" }}>
                            <Button className="btn-custom reset-btn"
                                onClick={resetFilter}
                            >
                                Reset
                            </Button>
                        </Col>
                    </div>

                </Row>
                <div className="container border rounded m-1 pt-3">
                {deliveryBoyRequests.length === 0 && !flag ?
                    <EmptyData />
                    :
                    deliveryBoyRequests.length === 0 && flag ?
                        <Loading />
                        :
                        <Table responsive className="table no-wrap">
                            <thead className="table-primary">
                                <tr>
                                    <th>Date</th>
                                    <th>Shop Details</th>
                                    <th>Customer Details</th>
                                    <th>Item Details</th>
                                    <th>Billing Details</th>
                                    <th>Distance/Charge</th>

                                </tr>
                            </thead>
                            <tbody>
                                {
                                    deliveryBoyRequests.length !== 0 && deliveryBoyRequests.filter((statusRequested) => statusRequested.deliveryStatus === "Delivered" && statusRequested.deliveryPaymentStatus !== "Settled to deliveryboy").map((request, index) => (
                                        <RequestItem key={index} request={request} fetchDeliveryBoyRequest={fetchDeliveryBoyRequest} />
                                    ))
                                }

                            </tbody>
                        </Table>
                }
                {deliveryBoyRequests.length !== 0 &&
                    <Table responsive className="table no-wrap">
                        <thead className="table-primary">
                            <tr>
                                <th>Total Amount</th>
                                <th>Service charge</th>
                                <th>Gross Amount</th>
                                <th>Add Payment</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    {paymentObj.totalAmount}
                                </td>
                                <td>
                                    {paymentObj.serviceCharge}
                                </td>
                                <td>
                                    {paymentObj.grossAmount}
                                </td>
                                <td>
                                    <Button className="btn-custom reset-btn" onClick={() => setShowModal(true)}>Add Payment</Button>

                                </td>
                            </tr>
                        </tbody>
                    </Table>
                }
                </div>
            </div>
            <ConfirmModal showModal={showModal} closeModal={closeModalHandler} confirmModal={addPayment} />
        </>
    )
}

export default AddDeliveryPayment;



