import React, { useState, useEffect } from "react";
import { Row, Col, Card, Form, Button, Alert, Tabs, Tab } from "react-bootstrap";
import api from '../../config/api';
import { getUserConfig } from "../../config/requestConfig";
import ForgetPassword from "../common/ForgetPassword";
import { errorMessage, successModule } from "../../utils/utils";
import LoginPage from "../common/LoginPage";
import RegisterPage from "../common/RegisterPage";
const Login = ({ setUser, props }) => {

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [postalOffice, setPostalOffice] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [loginId, setLoginId] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [loginMessage, setLoginMessage] = useState("");
  const [loginValidated, setLoginValidated] = useState(false);
  const [registerValidated, setRegisterValidated] = useState(false);
  const [showForgetPassword, setShowForgetPassword] = useState(false);

  const resetForm = () => {
    setName("");
    setEmail("");
    setAddress("");
    setPostalOffice("");
    setPostalCode("");
    setPhoneNumber("");
    setLoginId("");
    setPassword("");
  }
  //register submit 
  const onSumbitRegisterHandler = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      event.preventDefault();
      setRegisterValidated(true);
    }else{
      event.preventDefault();
      let content = { name, email, address, postalOffice, postalCode, phoneNumber, loginId, password }
      api.post(`/deliveryBoy/register`, content).then((response) => {
        resetForm();
        successModule("Email as been sent for account activation");
      });
    }
   
  }
  //login submit
  const onSumbitLoginHandler = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      event.preventDefault();
      setLoginValidated(true);
    }else{
      event.preventDefault();
      let content = { loginId, password }
      api.post("/deliveryBoy/login", content).then((res) => {
        console.log(res.data)
        let loggedUser = JSON.stringify(res.data);
        localStorage.setItem("loggedUser", loggedUser);
        setUser(loggedUser);
      }).catch((err) => {
        errorMessage("Invalid userId or password");
      })
    }
    
  }

  setTimeout(() => {
    setLoginMessage("");
  }, 10000);

  //forget password
  const closeForgetPassword = () => {
    setShowForgetPassword(false);

  }
  const openForgetPassword = () => {
    setShowForgetPassword(true);
  }

  const forgetPassword = (userId, setAlert) => {
    api.put(`/deliveryBoy/forgetPassword/${userId}`).then((response) => {
      setShowForgetPassword(true);
      let content = { "message": "Generated your new Password", "email": "Kindly Open your email account " }
      setAlert(content);
    }).catch((ex) => {
      errorMessage("Invalid User ID / User is Inactive");
    })
  }

  return (
    <>
      <Row className="login-page">
        <Col lg={8} className="d-flex left-section" style={{ position: "sticky", top: 0 }}>
          <img
            src={process.env.PUBLIC_URL + "/images/logo.png"}
            className="emblem"
          />
        </Col>
        <Col lg={4} className="right-section">
          <h4 style={{ align: "center" }}>
            <i className="fa fa-key"></i> USER
          </h4>
          <Tabs defaultActiveKey="login" id="uncontrolled-tab-example">
            <Tab eventKey="login" title="Login">

              <LoginPage
                userId={loginId}
                password={password}
                setUserId={setLoginId}
                setPassword={setPassword}
                submitHandler={onSumbitLoginHandler}
                forgetPassword={openForgetPassword}
                validated={loginValidated}
              />

            </Tab>
            <Tab eventKey="register" title="Register">
            <RegisterPage
              name={name}
              email={email}
              address={address}
              postalOffice={postalOffice}
              postalCode={postalCode}
              phoneNumber={phoneNumber}
              loginId={loginId}
              password={password}
              setName={setName}
              setEmail={setEmail}
              setAddress={setAddress}
              setPostalCode={setPostalCode}
              setPostalOffice={setPostalOffice}
              setPhoneNumber={setPhoneNumber}
              setLoginId={setLoginId}
              setPassword={setPassword}
              sumbitRegisterHandler={onSumbitRegisterHandler}
              validated={registerValidated}
            />
              <br />
              <br />
              <br/>
            </Tab>
          </Tabs>
        </Col>
      </Row>
      <ForgetPassword
        showForgetPassword={showForgetPassword}
        closeForgetPassword={closeForgetPassword}
        forgetPassword={forgetPassword}
      />


    </>
  );
};
export default Login;
