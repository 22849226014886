import React from "react";

const Loading = () => {
    return (
        <div className="d-flex justify-content-center">
            <div className="spinner-border text-info" style={{marginTop:"100px",width: "3rem", height: "3rem"}}></div>
        </div>
    )
}

export default Loading;