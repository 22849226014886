export const getShopConfig = () => {
    const shop = localStorage.getItem("loggedShop");
    if (shop) {
        const token = JSON.parse(shop)['auth-token'];
        
        return {
            headers: {
                'auth-token': token
            }
        }
    }
    else {
        return {};
    }

};

export const getUserConfig = () => {

    const user = localStorage.getItem("loggedUser");
    if (user) {
        const token = JSON.parse(user)['auth-token'];
        
        return {
            headers: {
                'auth-token': token
            }
        }
    }
    else {
        return {};
    }

};

export const getAdminConfig = () => {

    const admin = localStorage.getItem("loggedAdmin");
    if (admin) {
        const token = JSON.parse(admin)['auth-token'];
        
        return {
            headers: {
                'auth-token': token
            }
        }
    }
    else {
        return {};
    }

};




