import React, { useState, useEffect } from "react";
import { Button, Modal, Form, Row, Col, Card, Tabs, Tab, Table, Accordion } from "react-bootstrap";
import api from "../../config/api";
import { getAdminConfig } from "../../config/requestConfig";
import EmptyData from "../common/EmptyData";
import Loading from "../common/Loading";


//request detais
const RequestDetails = ({ shopBill, showRequestDetails, closeRequestDetails }) => {

    return (<Modal
        show={showRequestDetails}
        onHide={closeRequestDetails}
        aria-labelledby="contained-modal-title-vcenter"
        centered
    >
        <Modal.Header closeButton>
            <Modal.Title>Request Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Accordion defaultActiveKey="0">
                <Card>
                    {
                        shopBill.requests.map((request, index) => (
                            <>
                                <Accordion.Toggle as={Card.Header} eventKey={index ? index : "0"}>
                                    {request.name}
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey={index ? index : "0"}>
                                    <Card.Body>
                                        <Table responsive className="table table-bordered now-wrap">

                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <h6>Address :</h6>
                                                        {request.address}<br />
                                                        {request.postalOffice}<br />
                                                        {request.postalCode}<br />
                                                        {request.phoneNumber}<br />
                                                    </td>
                                                    <td>
                                                        <h6>Assigned To :</h6> {request.assignedTo.name}
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td>
                                                        <h6>Item Count :</h6>
                                                        {request.itemCount}
                                                    </td>
                                                    <td>
                                                        <h6>Items :</h6> {request.items.map((item, index) => {
                                                            return item + "; ";
                                                        })}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <h6>Approximate Weight :</h6> {request.weight}
                                                    </td>
                                                    <td>
                                                        <h6>Bill Amount : </h6>Rs. {request.billAmount}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <h6>Payment Type : </h6>{request.paymentType}
                                                    </td>
                                                    <td>
                                                        <h6>Payment Status : </h6>{request.paymentStatus}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <h6>Distance : </h6> {request.distance} KM
                                                    </td>
                                                    <td>
                                                        <h6>Delivery Charge : </h6> {request.deliveryCharge} €
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </>
                        ))
                    }
                </Card>
            </Accordion>

        </Modal.Body>
        <Modal.Footer>
            <Button className="box" onClick={closeRequestDetails}>Close</Button>
        </Modal.Footer>

    </Modal>)
}


const ShopBillItem = ({ shopBill, fetchShopBills, status }) => {

    const [showRequestDetails, setShowRequestDetails] = useState(false);
    // request details
    const closeRequestDetails = () => {
        setShowRequestDetails(false);
    }
    const openRequestDetails = () => {
        setShowRequestDetails(true);
    }
    //genderate date

    //createdAt
    var today = new Date(shopBill.createdAt),
        month =
            today.getMonth() + 1 < "10"
                ? "0" + (today.getMonth() + 1)
                : today.getMonth() + 1,
        day = today.getDate() < "10" ? "0" + today.getDate() : today.getDate(),
        createdAt = day + "-" + month + "-" + today.getFullYear();

    //receivedAt
    var today = new Date(shopBill.recievedAt),
        month =
            today.getMonth() + 1 < "10"
                ? "0" + (today.getMonth() + 1)
                : today.getMonth() + 1,
        day = today.getDate() < "10" ? "0" + today.getDate() : today.getDate(),
        recievedAt = day + "-" + month + "-" + today.getFullYear();

    return (
        <>
            <tr onClick={openRequestDetails}>
                {shopBill.paymentStatus === "Requested" ?
                    <td onClick={openRequestDetails}>{createdAt} </td> :
                    <td onClick={openRequestDetails}>{recievedAt} </td>
                }
                <td>{shopBill.shop.name}</td>
                <td>{shopBill.totalAmount} </td>

                <td>{shopBill.paymentReference} </td>
            </tr>
            <RequestDetails
                showRequestDetails={showRequestDetails}
                closeRequestDetails={closeRequestDetails}
                shopBill={shopBill} />
        </>
    )
}


const ShopBill = () => {

    //set shops and shopbills
    const [shopBills, setShopBills] = useState([]);
    const [tabKey, setTabKey] = useState("Requested");
    const [shops, setShops] = useState([]);
    //set filter shopbills details
    const [shopId, setShopId] = useState("");
    const [flag, setFlag] = useState(true)
    //filter shop bills
    useEffect(() => {
        fetchShopBills(shopId);
    }, [shopId]);
    useEffect(()=>{
        fetchShops();
    },[]);


    //get shopbill
    const fetchShopBills = (shopId) => {
        api.get(`/admin/shopBill/shop/${shopId}`, getAdminConfig()).then((response) => {
            setShopBills(response.data);
            setFlag(false);
        })
    }

    const fetchShops = () =>{
        api.get(`/admin/shop/`,getAdminConfig()).then((response)=>{
            setShops(response.data);
            setFlag(false);
        })
    }
    //reset filter shop bill by shop id
    const resetButton = () => {
        fetchShopBills("");
        setShopBills([]);
        setShopId("");
    }
    return (

        <div>
             <div className="container pt-3">
                <div className="d-flex align-items-center justify-content-between mb-3">
                    <div className="admin-header">Shop Bills</div> 
                </div>
            <br/>
            <Row>
            <div className="d-flex hstack gap-2 mt-0">
                <Col xs={6} sm={6} md={3} lg={3} style={{ padding: "5px" }}>
                    <Form.Group>
                        <Form.Label>Select Shop</Form.Label>
                        <Form.Control
                            as="select"
                            value={shopId}
                            onChange={(event) => setShopId(event.target.value)}
                        >
                            <option value="">Select</option>
                            {shops.map((shop, index) => {
                                return <option key={index} value={shop.id}>{shop.name}</option>
                            })
                            }
                        </Form.Control>
                    </Form.Group>
                </Col>
                <Col xs={6} sm={6} md={3} lg={3} style={{ padding: "12px",marginTop:"10px" }}>
                    <Button className="btn-custom reset-btn"
                        onClick={resetButton}
                    >
                        Reset
                    </Button>
                </Col>
            </div>
            </Row>
            <div className="container pt-3">
            <Tabs defaultActiveKey="Requested" id="uncontrolled-tab-example" activeKey={tabKey} onSelect={(k) => setTabKey(k)}>
                <Tab eventKey="Requested" title={"Requested (" + shopBills.filter((filterRequest) => filterRequest.paymentStatus === "Requested").length + ")"}>
                    {shopBills.filter((filterRequest) => filterRequest.paymentStatus === tabKey).length === 0&&!flag ?
                        <EmptyData />
                        :
                        shopBills.filter((filterRequest) => filterRequest.paymentStatus === tabKey).length === 0&&flag ?
                        <Loading/>
                        :
                        <Table className="table">
                            <thead className="table-primary">
                                <tr>
                                    <th>Requested Date</th>
                                    <th>Shop Name</th>
                                    <th>Total Amount</th>
                                    <th>Payment Reference</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    shopBills.length !== 0 && shopBills.filter((filterRequest) => filterRequest.paymentStatus === "Requested").map((shopBill, index) => (
                                        <ShopBillItem key={index} shopBill={shopBill} fetchShopBills={fetchShopBills} />
                                    ))
                                }
                            </tbody>
                        </Table>
                    }
                </Tab>
                <Tab eventKey="Received" title={"Received (" + shopBills.filter((filterRequest) => filterRequest.paymentStatus === "Received").length + ")"}>
                    {shopBills.filter((filterRequest) => filterRequest.paymentStatus === tabKey).length === 0&&!flag ?
                        <EmptyData />
                        :
                        shopBills.filter((filterRequest) => filterRequest.paymentStatus === tabKey).length === 0&&flag ?
                        <Loading />
                        :
                        <Table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th>Received Date</th>
                                    <th>Shop Name</th>
                                    <th>Total Amount</th>
                                    <th>Payment Reference</th>

                                </tr>
                            </thead>
                            <tbody>
                                {
                                    shopBills.length !== 0 && shopBills.filter((filterRequest) => filterRequest.paymentStatus === "Paid").map((shopBill, index) => (
                                        <ShopBillItem key={index} shopBill={shopBill} fetchShopBills={fetchShopBills} />
                                    ))
                                }
                            </tbody>
                        </Table>
                    }
                </Tab>

            </Tabs>
            </div>
            </div>
        </div>
    )
}
export default ShopBill;