import React, { useState, useEffect } from "react";
import { Row, Col, Button, Dropdown, Modal, Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import api from "../../config/api";
import { getUserConfig } from "../../config/requestConfig";
import EditProfile from "./EditProfile";
import ChangePassword from "../common/ChangePassword";
import { errorMessage, successModule } from "../../utils/utils";
import { Logout } from "../svgs/Logout";
import { Setting } from "../svgs/Setting";
const Header = ({ user, setUser }) => {
  const [showChangePassword, setShowChangePassword] = useState(false);

  const [showEditProfile, setShowEditProfile] = useState(false);
  const [userDetail, setUserDetail] = useState({});
  const history = useHistory();

  const handleSignout = () => {
    localStorage.removeItem("loggedUser");
    setUser("");
    history.push("/delivery-boy");
  }

  const closeEditProfile = () => {
    fetchUser()
    setShowEditProfile(false);
  }
  const openEditProfile = () => {
    setShowEditProfile(true);
  }


  const editProfileHandler = (data) => {
    api.put(`/deliveryBoy/`, data, getUserConfig()).then((response) => {
      // alert("add prpgram");
      setShowEditProfile(true);
      successModule("Successfully Updated");
    });
  }


  //change password
  const closeChangePassword = () => {
    setShowChangePassword(false);
  }
  const openChangePassword = () => {
    setShowChangePassword(true);
  }
  const changePasswordHandler = (newPassword, confirmPassword) => {
    let content = { "password": confirmPassword }
    if (newPassword === confirmPassword) {
      api.put(`/deliveryBoy/changePassword`, content, getUserConfig()).then((response) => {
        setShowChangePassword(true);
        successModule("Your password successfully updated");
      })
    } else {
      errorMessage("Confirm Password is mismatched");
    }
  }
  useEffect(() => {
    fetchUser();
  }, [])

  const fetchUser = () => {
    api.get(`/deliveryBoy/`, getUserConfig()).then((response) => {
      setUserDetail(response.data);
    });
  }

  return (
    <>
      <header className="container py-2 header-container">
          <div className="d-flex h-100 align-items-center justify-content-between">
            <div className="header-name px-3 py-3">Welcome {userDetail.name}</div>
            <div className="d-flex h-100 justify-content-end align-items-center">
              <div className="menu-toggle px-3"><i className={`fa fa-bars`} aria-hidden="true" ></i></div>
              <div className="hstack gap-2 px-3 menu-container">
                <Setting stroke='#404040' strokeWidth={1} />
                <div class="app-menu-list rounded shadow-sm border">
                  <div class="app-menu-item py-2 px-3 border-bottom" onClick={openEditProfile}>Edit Profile</div>
                  <div class="app-menu-item py-2 px-3 border-bottom" onClick={openChangePassword}>Change Password</div>
                </div>
                <div class="app-menu-list-arrow border-top border-start"></div>
              </div>
              <div className="hstack gap-2 px-3 menu-container" onClick={handleSignout}><Logout stroke='#404040' strokeWidth={1} /><div className="app-label">Sign Out</div></div>
            </div>
          </div>
      </header>
      <EditProfile
        showEditProfile={showEditProfile}
        closeEditProfile={closeEditProfile}
        editProfileHandler={editProfileHandler}
        user={userDetail}
      />
      <ChangePassword
        showChangePassword={showChangePassword}
        closeChangePassword={closeChangePassword}
        changePasswordHandler={changePasswordHandler}
      />
    </>
  );
};
export default Header;
