import toastr from 'toastr'
import moment from 'moment'
import '../../node_modules/toastr/build/toastr.min.css'

toastr.options = {
  hideDuration: 300,
  timeOut: 3000,
  "positionClass": "toast-bottom-right",
}

export function handleError(error) {
  const statusCode = error?.data?.statusCode
  if (statusCode === 401) {
    window.location.href = '/'
  }
  else if (statusCode === 422)
  {
    let msg = toastMessage("error422", "")
    errorMessage(msg)
  }
  else if (statusCode === 400)
  {
    let msg = toastMessage("error400", "")
    errorMessage(msg)
  }
  else if (statusCode === 404)
  {
    let msg = toastMessage("error404", "")
    errorMessage(msg)
    }
  else if (statusCode === 409)
  {
    let msg = toastMessage("error406", "")
    errorMessage(msg)
  }
  else if (error.status === 500)
  {
    let msg = toastMessage("error500", "")
    errorMessage(msg)
    }
}

export function errorMessage(message) {
  toastr.error(message)
}

export function successModule(message) {
  toastr.success(message)
}

export function successMessage(message) {
  // toastr.success(message)
}

export function toastMessage(key, type) {
  const message = {
    successMessage: `${type} successfully`,
    createMessage: `${type} created successfully`,
    updateMessage: `${type} updated successfully`,
    deleteMessage: `${type} deleted successfully`,
    error400: 'Invalid request',
    error401: 'Invalid credentials',
    error403: 'Resouce is restricted',
    error404: 'Not found',
    error406: 'Record already exists',
    error422: 'Unprocessable entity',
    error423: 'Access to this resource is denied',
    error500: 'Internal server error',
  }
  return message[key]
}

export function tableDisplayDate(parameter) {
  return !parameter ? '-' : moment(parameter).local().format('DD MMM YYYY hh:mm a')
}

export function displayDate(parameter) {
  return !parameter ? '-' : moment(parameter).local().format('DD MMM YYYY')
}

export const sidebarShow = (flag)=>{
  const sideBar = document.querySelector('.sidebar');

  if(flag)sideBar.classList.toggle('active');
  else sideBar.classList.toggle('active');

  const menuItems = document.querySelectorAll('.text');
  menuItems.forEach(menuItem=>{
    menuItem.addEventListener('click',()=>{
        sideBar.classList.remove('active');
      })
  })
}
