import React from "react";

const EmptyData = () => {
    return (
        <div className="container m-4">
            <div className="d-flex align-items-center p-5">No Data Found!</div>
        </div>
    )
}

export default EmptyData;