import React from "react"
import { Modal,Button } from "react-bootstrap";
const ConfirmModal = ({showModal, closeModal,confirmModal}) =>{
 return (
            <Modal 
            show={showModal} 
            onHide={closeModal}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            >
            <Modal.Header className="pb-3 px-3 pt-4">
                <Modal.Title>Confirmation</Modal.Title>
                <div class="close-container" onClick={closeModal}>
                    <div class="close-line1"></div>
                    <div class="close-line2"></div>
                </div>
            </Modal.Header>
            <Modal.Body>
                <p>Are you sure you want to do this?</p>
            </Modal.Body>
            <div className="p-4">
                <Button className="btn-custom close-btn" variant="secondary" onClick={closeModal}>Cancel</Button>
                <Button className="btn-custom reset-btn" variant="primary" onClick={confirmModal}>Yes, Sure</Button>
            </div>
                
            </Modal>
        )
}

export default ConfirmModal;