import React from "react";
import { Modal, Form, Button } from 'react-bootstrap';
const EditProfileModal = (props) => {
    return (
        <Modal
            show={props.showEditProfile}
            onHide={props.closeEditProfile}
            aria-labelledby="contained-modal-title-vcenter"
            size="xl"
            centered
        >
            <Modal.Header className="pb-3 px-3 pt-4">
                <Modal.Title>Edit Profile</Modal.Title>
                <div class="close-container" onClick={props.closeEditProfile}>
                <div class="close-line1"></div>
                <div class="close-line2"></div>
                </div>
            </Modal.Header>
        
                <Form noValidate validated={props.validated} onSubmit={props.submitHandler}>
                <Modal.Body>
                    <Form.Group>
                        <Form.Label>Name <span className="required">*</span></Form.Label>
                        <Form.Control type="text" value={props.name} onChange={(event) => props.setName(event.target.value)} required />
                        <Form.Control.Feedback type="invalid">
                            Name is required
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Address <span className="required">*</span></Form.Label>
                        <Form.Control type="textarea" value={props.address} onChange={(event) => props.setAddress(event.target.value)} required />
                        <Form.Control.Feedback type="invalid">
                            Address is required
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Postal Office <span className="required">*</span></Form.Label>
                        <Form.Control type="text" value={props.postalOffice} onChange={(event) => props.setPostalOffice(event.target.value)} required />
                        <Form.Control.Feedback type="invalid">
                            Postal Office is required
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Postal Code <span className="required">*</span></Form.Label>
                        <Form.Control type="number" value={props.postalCode} onChange={(event) => props.setPostalCode(event.target.value)} required />
                        <Form.Control.Feedback type="invalid">
                            Postal Code is required
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Phone Number <span className="required">*</span></Form.Label>
                        <Form.Control type="number" value={props.phoneNumber} onChange={(event) => props.setPhoneNumber(event.target.value)} required />
                        <Form.Control.Feedback type="invalid">
                            Phone Number is required
                        </Form.Control.Feedback>
                    </Form.Group>
                    </Modal.Body>
                    <div className="p-4">
                    <Button className="btn-custom reset-btn" onClick={props.closeEditProfile}>Close</Button>
                    <Button type="submit" className="btn-custom close-btn" >Update</Button>
                   
                    </div>
                   
                </Form>
          

        </Modal>
    )
}

export default EditProfileModal;