import React, { useState, useEffect } from "react";
import { Modal, Form, Button,Alert } from "react-bootstrap";
import api from "../../config/api";
import { getUserConfig } from "../../config/requestConfig";
import EditProfileModal from "../common/EditProfileModal";
const EditProfile = ({
    showEditProfile,
    closeEditProfile,
    editProfileHandler,
    user
}) => {
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [postalOffice, setPostalOffice] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [validated, setValidated] = useState(false);
  useEffect(()=>{
    setName(user.name);
      setAddress(user.address);
      setPostalOffice(user.postalOffice);
      setPostalCode(user.postalCode);
      setPhoneNumber(user.phoneNumber);
  },[user]);

  const onSubmitHandler= (event)=>{
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      event.preventDefault();
      setValidated(true);
    }
    else{
      event.preventDefault();
      let content = {name,address,postalOffice,postalCode,phoneNumber}
      editProfileHandler(content);
    }
    
  }
  return (

    <EditProfileModal
      name={name}
      address={address}
      postalOffice={postalOffice}
      postalCode={postalCode}
      phoneNumber={phoneNumber}
      setName={setName}
      setAddress={setAddress}
      setPostalCode={setPostalCode}
      setPostalOffice={setPostalOffice}
      setPhoneNumber={setPhoneNumber}
      submitHandler={onSubmitHandler}
      validated={validated}
      showEditProfile={showEditProfile}
      closeEditProfile={closeEditProfile}
    />
    
  );
};

export default EditProfile;
