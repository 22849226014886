import React, { useState, useEffect } from "react";
import { Modal, Form, Button, Alert } from "react-bootstrap";

const ChangePassword = ({
    showChangePassword,
    closeChangePassword,
    changePasswordHandler,
}) => {
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [validated, setValidated] = useState(false);

    const onSubmitHandler = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation();
            event.preventDefault();
            setValidated(true);
        }
        else {
            event.preventDefault();

            changePasswordHandler(newPassword, confirmPassword);
            setNewPassword("");
            setConfirmPassword("");
        }

    }


    return (
        <Modal
            show={showChangePassword}
            onHide={closeChangePassword}
            aria-labelledby="contained-modal-title-vcenter"
            size="xl"
            centered
        >
            <Modal.Header className="pb-3 px-3 pt-4">
                <Modal.Title>Change Password</Modal.Title>
                <div class="close-container" onClick={closeChangePassword}>
                    <div class="close-line1"></div>
                    <div class="close-line2"></div>
                </div>
            </Modal.Header>

            <Form noValidate validated={validated} onSubmit={onSubmitHandler}>
                <Modal.Body>
                    <div className="row justify-content-center">
                        <div className="col-md-6">
                            <Form.Group>
                                <Form.Label>New Password <span className="required">*</span></Form.Label>
                                <Form.Control type="text" value={newPassword} onChange={(event) => setNewPassword(event.target.value)} required />
                                <Form.Control.Feedback type="invalid">
                                    New password is required
                                </Form.Control.Feedback>
                            </Form.Group>
                        </div>
                        <div className="col-md-6">
                            <Form.Group>
                                <Form.Label>Confirm Password <span className="required">*</span></Form.Label>
                                <Form.Control type="text" value={confirmPassword} onChange={(event) => setConfirmPassword(event.target.value)} required />
                                <Form.Control.Feedback type="invalid">
                                    Confirm password is required
                                </Form.Control.Feedback>
                            </Form.Group>
                        </div>
                    </div>
                </Modal.Body>
                <div className="p-4">
                    <Button className="btn-custom reset-btn" onClick={closeChangePassword}>Close</Button>
                    <Button type="submit" className="btn-custom close-btn">Save</Button>
                </div>
            </Form>
        </Modal>
    );
};

export default ChangePassword;
