import React, { useState, useEffect } from "react";
import { Row, Col, Button, Dropdown, Modal, Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import api from "../../config/api";
import { getShopConfig } from "../../config/requestConfig";
import EditProfile from "./EditProfile";
import ChangePassword from "../common/ChangePassword";
import { successModule, errorMessage, sidebarShow } from "../../utils/utils";
import { Logout } from "../svgs/Logout";
import { Setting } from "../svgs/Setting";


//view api

const Api = ({ showApi, closeApi, shopDetail }) => {
  const shop = localStorage.getItem("loggedShop");
  const token = JSON.parse(shop)['auth-token'];
  return (
    <Modal
      show={showApi}
      onHide={closeApi}
      aria-labelledby="contained-modal-title-vcenter"
      size="xl"
      centered
    >

      <Modal.Header className="pb-3 px-4 pt-4">
        <Modal.Title>API</Modal.Title>
        <div class="close-container" onClick={closeApi}>
                <div class="close-line1"></div>
                <div class="close-line2"></div>
                </div>
      </Modal.Header>
      <Modal.Body style={{ 'max-height': 'calc(100vh - 210px)', 'overflow-y': 'auto' }}>
        <div className="container">
          <p>Merchant Id : {shopDetail.loginId}</p>
          <p>AuthToken : {token}</p>
          <h4>Api call</h4>
          <p>http://delivero.com/api/shop/deliveryRequest</p>
          <p>http://delivero.com/api/shop/deliveryRequest/Requested</p>
          <p>http://delivero.com/api/shop/deliveryRequest/Assigened</p>
          <p>http://delivero.com/api/shop/deliveryRequest/Pickedup</p>
          <p>http://delivero.com/api/shop/deliveryRequest/Delivered</p>
          <p>http://delivero.com/api/shop/bill/</p>
          <p><b>Example:</b></p>
          'method': 'GET',<br />
          'url': 'http://localhost:3001/api/shop/bill/',<br />
          'headers': <br />
          - 'Content-Type': 'application/json',<br />
          - 'auth-token': {shopDetail.authToken}
          ,<br />

        </div>
      </Modal.Body>
      <div className="p-4">
        <Button
          className="btn-custom close-btn w-100"
          onClick={closeApi}
        >
          Close
        </Button>
      </div>
    </Modal>
  )
}



const Header = ({ shop, setShop }) => {

  const [showChangePassword, setShowChangePassword] = useState(false);
  const [showEditProfile, setShowEditProfile] = useState(false);
  const [showApi, setShowApi] = useState(false);
  const [shopDetail, setShopDetail] = useState([]);
  const [sidemenu, setSidemenu] = useState(false);
  const history = useHistory();

  const handleSignout = () => {
    localStorage.removeItem("loggedShop");
    setShop("");
    history.push("/shop");
  }

  //edit Profile
  const closeEditProfile = () => {
    fetchShop()
    setShowEditProfile(false);
  }
  const openEditProfile = () => {
    setShowEditProfile(true);
  }
  const editProfileHandler = (data) => {
    api.put(`/shop/`, data, getShopConfig()).then((response) => {
      // alert("add prpgram");
      setShowEditProfile(true);
      successModule("Successfully Updated");
    });
  }


  //change password
  const closeChangePassword = () => {
    setShowChangePassword(false);
  }
  const openChangePassword = () => {
    setShowChangePassword(true);
  }
  const changePasswordHandler = (newPassword, confirmPassword) => {
    let content = { "password": confirmPassword }
    if (newPassword === confirmPassword) {
      api.put(`/shop/changePassword`, content, getShopConfig()).then((response) => {
        setShowChangePassword(true);
        successModule("Your password successfully updated");
      })
    } else {
      errorMessage("Confirm Password is mismatched");
    }
  }

  useEffect(() => {
    fetchShop();
  }, [])
  //open api
  const closeApi = () => {
    setShowApi(false);
  }
  const openApi = () => {
    setShowApi(true);
  }
  const fetchShop = () => {
    api.get(`/shop/`, getShopConfig()).then((response) => {
      setShopDetail(response.data);
    });
  }

  const sideMenuHandler = (flag) => {
    setSidemenu(flag)
    sidebarShow(flag)
  }

  return (
    <>
      <header className="container py-2 header-container">
        <div className="d-flex h-100 align-items-center justify-content-between">
          <div className="header-name px-3 py-3">Welcome {shopDetail.name}</div>
          <div className="d-flex h-100 justify-content-end align-items-center">
            <div className="menu-toggle px-3" onClick={() => sideMenuHandler(sidemenu ? false : true)}><i className={`fa fa-bars`} aria-hidden="true" ></i></div>
            <div className="hstack gap-2 px-3 menu-container">
              <Setting stroke='#404040' strokeWidth={1} />
              <div class="app-menu-list rounded shadow-sm border">
                <div class="app-menu-item py-2 px-3 border-bottom" onClick={openEditProfile}>Edit Profile</div>
                <div class="app-menu-item py-2 px-3 border-bottom" onClick={openChangePassword}>Change Password</div>
                <div class="app-menu-item py-2 px-3 border-bottom" onClick={openApi}>API</div>
              </div>
              <div class="app-menu-list-arrow border-top border-start"></div>
            </div>
            <div className="hstack gap-2 px-3 menu-container" onClick={handleSignout}><Logout stroke='#404040' strokeWidth={1} /><div className="app-label">Sign Out</div></div>
          </div>

        </div>
      </header>

      <EditProfile
        showEditProfile={showEditProfile}
        closeEditProfile={closeEditProfile}
        editProfileHandler={editProfileHandler}
        shop={shopDetail}

      />
      <ChangePassword
        showChangePassword={showChangePassword}
        closeChangePassword={closeChangePassword}
        changePasswordHandler={changePasswordHandler}
      />
      <Api
        showApi={showApi}
        closeApi={closeApi}
        shopDetail={shopDetail}
      />
    </>
  );
};
export default Header;
