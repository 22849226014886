import React, { useState, useEffect } from "react";
import { Button, Modal, Form, Row, Col, Card, Tabs, Tab, Table } from "react-bootstrap";
import api from "../../config/api";
import { getShopConfig } from "../../config/requestConfig";
import { EditRequest } from "./Request";
import { successModule } from "../../utils/utils";
import EmptyData from "../common/EmptyData";
import Loading from "../common/Loading";
const RequestItem = ({ request, fetchRequest, status,startDate, endDate }) => {

    const [showEditRequest, setShowEditRequest] = useState(false);
    const closeEditRequest = () => {
        setShowEditRequest(false);
    }
    const openEditRequest = () => {
        setShowEditRequest(true);
    }

    var today = new Date(request.requestedAt),
    // get month in two digit
    month =
        today.getMonth() + 1 < "10"
            ? "0" + (today.getMonth() + 1)
            : today.getMonth() + 1,
    // get month in two digit
    day = today.getDate() < "10" ? "0" + today.getDate() : today.getDate(),
    // get current date
    date = day + "-" + month + "-" + today.getFullYear();

    const editRequest = (requestId, paymentType, paymentStatus) => {

        let content = { paymentType, paymentStatus }
        api.put(`/shop/deliveryRequest/${requestId}`, content, getShopConfig()).then((response) => {
            setShowEditRequest(false);
            fetchRequest(startDate,endDate);
            successModule("Delivery Item Updated.")
        })

    }


    return (
        <>
            <tr>
                <td>{date}</td>
                <td>{request.name}<br />{request.address}<br />{request.postalOffice}<br />{request.postalCode}<br />{request.phoneNumber} </td>
                {status !== "Requested" && <td>{request.assignedTo.name}</td>}

                <td>Item Count : {request.itemCount}<br />
                    Items : <br />{request.items.map((item, index) => {
                        return item + "; ";
                    })} <br /> Approximate weight : {request.weight} KG
                </td>
                <td>Bill Amount : {request.billAmount}<br /> Payment Type : <br /> {request.paymentType} <br /> Payment status : {request.paymentStatus}  </td>

                <td>Distance : {request.distance} KM <br /> Charge : {request.deliveryCharge} € </td>

                <td>{request.paymentStatus === "Waiting" ?
                    <Button className="btn-custom reset-btn" onClick={openEditRequest}>Edit</Button> :
                    <Button className="btn-custom reset-btn" onClick={openEditRequest} disabled>Edit</Button>}
                </td>
            </tr>
            <EditRequest
                showEditRequest={showEditRequest}
                closeEditRequest={closeEditRequest}
                editRequest={editRequest}
                request={request}
            />
        </>
    )
}
export const DeliveryDetails = ({ status, requests, fetchRequest, flag, startDate, endDate }) => {

    const [requestItems, setRequestsItems] = useState([]);
    useEffect(() => {
        setRequestsItems(requests)
    }, [requests])
    return (
        requestItems.filter((requestItem) => requestItem.deliveryStatus === status).length === 0 && flag ?
            <Loading />
            :
            requestItems.filter((requestItem) => requestItem.deliveryStatus === status).length === 0 && !flag ?
                <EmptyData />
                :
                <Table responsive className="table no-wrap">
                    <thead className="table-primary">
                        <tr>
                            <th className="text-truncate">Date</th>
                            <th className="text-truncate">Customer Details</th>
                            {status !== "Requested" && <th className="text-truncate">Assigned</th>}
                            <th className="text-truncate">Item Details</th>
                            <th className="text-truncate">Billing Details</th>
                            <th className="text-truncate">Distance/Charge</th>
                            <th className="text-truncate">Payment</th>
                        </tr>
                    </thead>
                    <tbody>

                        {
                            requestItems.length !== 0 && requestItems.filter((requestItem) => requestItem.deliveryStatus === status).map((request, index) => (
                                <RequestItem key={index} request={request} status={status} fetchRequest={fetchRequest} startDate={startDate} endDate={endDate} />
                            ))
                        }
                    </tbody>
                </Table>

    )
}
